import React, { useEffect, useState } from 'react';

// external css
// redux and react hooks
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

// child component
import { HeadingPictureComponent } from '../../Components';
import { ImageComponent, TickerText } from '../../Components';
import Footer from "../../Layouts/Footer/Footer";

// packages
import OwlCarousel from 'react-owl-carousel';

// Actions & RTK query or mutations
import { useLazyAboutUsCountQuery } from '../../Services/modules/AboutUs';
import { updateLocoScroll } from '../../Store/UI';
import { Helmet } from 'react-helmet';


// Constants
import { catsOneText } from '../../resources/TextRollerConstants';
import { AboutUsOptions } from '../../resources/OwlCarouselOptions';
import { URL } from '../../resources/Url';
import { routesName } from '../../resources/RoutesName';
import { MetaData, MetaDataAj, MetaDataTfm } from '../../resources/MetaData';
import { Config } from '../../Config';


const AboutUs = () => {

      const [screenWidth, setScreenWidth] = useState(window.innerWidth);

      const browserDetect     =     useSelector(state=>state.ui.browserDetect);
      const domain            =     useSelector(state=>state.user.domain);

      const [fetchAboutUsCount, { data }]    =    useLazyAboutUsCountQuery();
      const dispatch    =     useDispatch();

      useEffect(()=>{
            fetchAboutUsCount();
      },[fetchAboutUsCount])

      useEffect(()=>{

            if(data){
                  if(data.status ==="1"){
                        dispatch(updateLocoScroll({locoScroll:{change:true,type:'update'}}))
                  }
            }

      },[data, dispatch])

      useEffect(()=>{
            const handleResize = () => setScreenWidth(window.innerWidth);
            window.addEventListener('resize', handleResize);
      },[])

      let carbonOffset = 0
      let learnNewVenues = 0
      let membersActivelyRecommend = "0%"
      let membersMakePurchase = "0%"
      let membersSpend = "0%"
      let month = 0
      let ticketingPartners = 0
      let trees = 0

      if(data){
            if(data.data){
                  carbonOffset = data.data.carbonOffset
                  learnNewVenues = data.data.learn_new_venues
                  membersActivelyRecommend = data.data.members_actively_recommend
                  membersMakePurchase = data.data.members_make_purchase
                  membersSpend = data.data.members_spend
                  month = data.data.month
                  ticketingPartners = data.data.ticketing_partners
                  trees = data.data.trees
            }

      }

      let metaDataSite = MetaData
      if(domain === Config.DOMAIN_CONSTANT_TFM){
            metaDataSite = MetaDataTfm
      } else if(domain === Config.DOMAIN_CONSTANT_AJTIX){
            metaDataSite = MetaDataAj
      }
      
      return (
            
            <React.Fragment>
                  {/* <!-- REQUIRED wrapper div for parallax --> */}
                  
                  <div className="scroll-animations position-relative" data-scroll-container>

                        {domain === Config.DOMAIN_CONSTANT_AJTIX ? 
                              <Helmet>
                                    <meta name="robots" content="noindex, nofollow" />
                                    <title>Hidden Page</title>
                              </Helmet>
                        :
                        <Helmet>
                              <title>{metaDataSite.About.title}</title>
                              <meta name="description" content={metaDataSite.About.description} />
                              <meta name="keywords" content={metaDataSite.About.keywords} />
                        </Helmet>
                        }

                        {/* <!-- Please mind the class --> */}
                        <main className="main__about-us" data-bs-spy="scroll" data-bs-target="#spyScrollbarNav">

                              <div className="spy-scrollbar d-none d-lg-block">
                                    <div className="spy-scrollbar__content">
                                          <ul className="nav spy-scrollbar__nav flex-column" id="spyScrollbarNav">
                                                <li className="nav-item">
                                                      <a className="nav-link text-uppercase" href="#sectionNHSCT" data-scroll-to>
                                                            NHS CHARITIES TOGETHER
                                                      </a>
                                                </li>
                                                <li className="nav-item">
                                                      <a className="nav-link text-uppercase" href="#sectionAboutUs" data-scroll-to>
                                                            ABOUT US
                                                      </a>
                                                </li>
                                                <li className="nav-item">
                                                      <a className="nav-link text-uppercase" href="#sectionEcologi" data-scroll-to>
                                                            CLIMATE POSITIVE
                                                      </a>
                                                </li>
                                                <li className="nav-item">
                                                      <a className="nav-link text-uppercase" href="#sectionOurNumbers" data-scroll-to>
                                                            OUR NUMBERS
                                                      </a>
                                                </li>
                                                <li className="nav-item">
                                                      <a className="nav-link text-uppercase" href="#sectionOurValues" data-scroll-to>
                                                            VALUES
                                                      </a>
                                                </li>
                                                <li className="nav-item">
                                                      <a className="nav-link text-uppercase" href="#sectionListingShowSteps" data-scroll-to>
                                                            LISTINGS
                                                      </a>
                                                </li>
                                                <li className="nav-item">
                                                      <a className="nav-link text-uppercase" href="#sectionThanks" data-scroll-to>
                                                            THANKS
                                                      </a>
                                                </li>
                                          </ul>
                                    </div>
                                    <div className="spy-scrollbar__line"></div>
                                    <div className="spy-scrollbar__progress"></div>
                              </div>

                              <section data-scroll-section id="sectionNHSCT" className="section__NHSCT bg-blue pb-0">
                                    <div data-scroll data-scroll-repeat data-inview="section__NHSCT" className="container-fluid">
                                          <div className="row">
                                                <div className="col-md-12">

                                                      <div className="content content__NHSCT mx-auto">
                                                            <div className="content__header">
                                                                  <div className="big-heading text-center">
                                                                        <h1 className="h2 mb-0">We work in support <br className="d-none d-lg-block"/>of good causes</h1>
                                                                        {domain === "" &&
                                                                        <>
                                                                        <HeadingPictureComponent dataScroll className="ticket-yellow"
                                                                        srcSet="ticket-about-big-heading1-yellow.svg"
                                                                        src="ticket-about-big-heading1-yellow-sm.svg"
                                                                        />

                                                                        <HeadingPictureComponent dataScroll className="ticket-orange"
                                                                        srcSet="ticket-about-big-heading1-orange.svg"
                                                                        src="ticket-about-big-heading1-orange-sm.svg"
                                                                        />

                                                                        <HeadingPictureComponent dataScroll className="ticket-aquamarine"
                                                                        srcSet="ticket-about-big-heading1-aquamarine.svg"
                                                                        src="ticket-about-big-heading1-aquamarine-sm.svg"
                                                                        />
                                                                        </>}
                                                                       
                                                                  </div>
                                                            </div>
                                                            {/* <!-- .content__header --> */}

                                                            <div className="content__body text-center text-xl-start">
                                                                  <div className="wrapper d-flex flex-column flex-xl-row">
                                                                        <div className="col__left">
                                                                              <p className="lead fw-bold mb-0">
                                                                                    Alongside our members, we raise funds for NHS Charities Together who help our health service to do more: from supporting research and development, to brightening up hospital environments, donating state-of-the-art technology and equipment, mobilising volunteers, touching lives and making a huge difference to millions of people when they are at their most vulnerable.
                                                                              </p>

                                                                              <div className="buttons-wrapper justify-content-center justify-content-xl-start">
                                                                                    <a className="btn btn-xl-lg btn-primary" href="https://ticketsformedics.co.uk/" rel="noreferrer" target="_blank">Visit their website</a>
                                                                              </div>
                                                                              {/* <!-- .buttons-wrapper --> */}
                                                                        </div>
                                                                        <div className="col__right">
                                                                              <div>
                                                                                    <a href="https://nhscharitiestogether.co.uk/" rel="noreferrer" target="_blank">
                                                                                          <ImageComponent className="image logo-nhs-charities" srcSet={`logo-nhs-charities-together-white-369@2x.png`}
                                                                                          src={`logo-nhs-charities-together-white-369.png`} type="images"/>
                                                                                    </a>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                            {/* <!-- .content__body --> */}
                                                      </div>
                                                      {/* <!-- .content --> */}

                                                </div>
                                                {/* <!-- .col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}

                                          <div className="row">
                                                <div className="col-md-12 col-extend-fw">

                                                      {/* <!-- CAROUSEL --> */}
                                                      <div data-background="blue" className="w-100">
                                                            <OwlCarousel  className="owl-carousel owl-carousel__about-us owl-theme h-100" loop {...AboutUsOptions}>
                                                                  <div className="item">
                                                                        <div className="item__image">
                                                                              {/* <ImageComponent className="carousel-image object-fit" 
                                                                              srcSet={`${URL.Image_Base_Url}iStock-542088116.jpg`}
                                                                              src={`${URL.Image_Base_Url}iStock-542088116.jpg`}
                                                                              /> */}

                                                                              <img className="carousel-image object-fit"  srcSet={`${URL.Image_Base_Url}iStock-542088116.jpg`} src={`${URL.Image_Base_Url}iStock-542088116.jpg`} alt="" />

                                                                        </div>
                                                                  </div>
                                                                  <div className="item">
                                                                        <div className="item__image">
                                                                              {/* <ImageComponent className="carousel-image object-fit" 
                                                                              srcSet={`${URL.Image_Base_Url}iStock-1212167952.jpg`}
                                                                              src={`${URL.Image_Base_Url}iStock-1212167952.jpg`}
                                                                              /> */}

                                                                              <img className="carousel-image object-fit"  srcSet={`${URL.Image_Base_Url}iStock-1212167952.jpg`} src={`${URL.Image_Base_Url}iStock-1212167952.jpg`} alt="" />
                                                                        </div>
                                                                  </div>
                                                                  <div className="item">
                                                                        <div className="item__image">
                                                                              {/* <ImageComponent className="carousel-image object-fit" 
                                                                              srcSet={`${URL.Image_Base_Url}iStock-465180779.jpg`}
                                                                              src={`${URL.Image_Base_Url}iStock-465180779.jpg`}/> */}

                                                                              <img className="carousel-image object-fit"  srcSet={`${URL.Image_Base_Url}iStock-465180779.jpg`} src={`${URL.Image_Base_Url}iStock-465180779.jpg`} alt="" />
                                                                        </div>
                                                                  </div>
                                                                  <div className="item">
                                                                        <div className="item__image">
                                                                              {/* <ImageComponent className="carousel-image object-fit" 
                                                                              srcSet={`${URL.Image_Base_Url}iStock-914628584.jpg`}
                                                                              src={`${URL.Image_Base_Url}iStock-914628584.jpg`}
                                                                              /> */}

                                                                              <img className="carousel-image object-fit"  srcSet={`${URL.Image_Base_Url}iStock-914628584.jpg`} src={`${URL.Image_Base_Url}iStock-914628584.jpg`} alt="" />
                                                                        </div>
                                                                  </div>
                                                                  <div className="item">
                                                                        <div className="item__image">
                                                                              {/* <ImageComponent className="carousel-image object-fit"  
                                                                              srcSet={`${URL.Image_Base_Url}iStock-911008782.jpg`}
                                                                              src={`${URL.Image_Base_Url}iStock-911008782.jpg`}
                                                                              /> */}

                                                                              <img className="carousel-image object-fit"  srcSet={`${URL.Image_Base_Url}iStock-911008782.jpg`} src={`${URL.Image_Base_Url}iStock-911008782.jpg`} alt="" />
                                                                        </div>
                                                                  </div>
                                                            </OwlCarousel>
                                                            {/* <!-- /.owl-carousel__about-us --> */}
                                                      </div>

                                                </div>
                                                {/* <!-- .col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}
                                    </div>
                                    {/* <!-- /.container-fluid  --> */}
                              </section>
                              {/* <!-- /#sectionNHSCT --> */}

                              <section data-scroll-section id="sectionAboutUs" className="section__about-us">
                                    <div data-scroll data-scroll-repeat data-inview="section__about-us" className="container-fluid">
                                          <div className="row">
                                                <div className="col-md-12">

                                                      <div className="content content__about-us mx-auto">
                                                            <div className="content__header">
                                                                  <div className="big-heading text-center">
                                                                        <h2 className="mb-0">
                                                                              We believe in <br className="d-none d-xl-block"/>using our platform for good</h2>
                                                                        <HeadingPictureComponent
                                                                        dataScroll 
                                                                        className="ticket-blue"
                                                                        srcSet="ticket-about-big-heading2-blue.svg"
                                                                        src="ticket-about-big-heading2-blue-sm.svg"
                                                                        />

                                                                        <HeadingPictureComponent
                                                                        dataScroll 
                                                                        className="ticket-orange"
                                                                        srcSet="ticket-about-big-heading2-orange.svg"
                                                                        src="ticket-about-big-heading2-orange-sm.svg"
                                                                        />

                                                                        <HeadingPictureComponent
                                                                        dataScroll 
                                                                        className="ticket-green"
                                                                        srcSet="ticket-about-big-heading2-green.svg"
                                                                        src="ticket-about-big-heading2-green-sm.svg"
                                                                        />

                                                                        <HeadingPictureComponent
                                                                        dataScroll 
                                                                        className="ticket-yellow"
                                                                        srcSet="ticket-about-big-heading2-yellow.svg"
                                                                        src="ticket-about-big-heading2-yellow-sm.svg"
                                                                        />

                                                                        <HeadingPictureComponent
                                                                        dataScroll 
                                                                        className="ticket-aquamarine"
                                                                        srcSet="ticket-about-big-heading2-aquamarine.svg"
                                                                        src="ticket-about-big-heading2-aquamarine-sm.svg"
                                                                        />

                                                                  </div>
                                                            </div>
                                                            {/* <!-- .content__header --> */}
                                                            <div className="content__body">
                                                                  <div className="wrapper d-flex flex-column flex-xl-row">
                                                                        <div className="col__left flex-shrink-xl-0">
                                                                              <p className="lead fw-bold text-center text-lg-start">
                                                                                    We offer a free seat-filling service that connects productions with new audiences in a way that is socially and climate responsible.
                                                                              </p>
                                                                              <div className="feature">
                                                                                    <ImageComponent className="image w-100" 
                                                                                    srcSet={`${URL.Image_Base_Url}iStock-1207069904.jpg`}  src={`${URL.Image_Base_Url}iStock-1207069904.jpg`}/>
                                                                              </div>
                                                                        </div>
                                                                        <div className="col__right">
                                                                              {domain === "" ? <>
                                                                              <p>
                                                                                    We started Central Tickets in 2017 having seen an opportunity to build a theatre-going community who would react quickly to offers in return for low-priced tickets and a diverse range of shows.
                                                                              </p>
                                                                              <p>
                                                                                    From experience, we know the challenges of distributing discounted tickets, but we also recognise the importance of having an audience who respond to offers, spend well at venues and continue to buy regular priced theatre tickets.
                                                                              </p>
                                                                              <p>
                                                                                    We offer 100+ seat-filling shows each week and we also connect our members with opportunities to purchase regular tickets via third-parties where they can secure great discounts in various campaigns throughout the year.
                                                                              </p>
                                                                              <p>
                                                                                    Since we began in 2017, we have grown an engaged community of members who have really embraced the idea that they are providing a valuable service by filling empty seats in return for the discounted tickets. In 2020 they rallied to raise <a className='no-text-color' href='https://www.crowdfunder.co.uk/p/centraltickets' target="_blank" rel='noreferrer'>£47K of donations</a> to help venues and performers impacted by theatre closures.
                                                                              </p>
                                                                              <p>
                                                                                    Our work was recognised by the <a className='no-text-color' href='https://www.fsb.org.uk/about-us.html' target="_blank" rel='noreferrer'>FSB</a> who named us ‘Start Up of the Year’ in 2018 for the London area.  More recently, we’ve been featured in <a className='no-text-color' href='https://www.theguardian.com/stage/2018/oct/31/secrets-of-the-seat-fillers-london-free-theatre-tickets' target="_blank" rel='noreferrer'>the Guardian</a>, <a className='no-text-color' href='https://www.moneysavingexpert.com/team-blog/2019/03/grab-theatre-tickets-from-p3-via-a-secret-seat-filling-site/' target="_blank" rel='noreferrer'>Money Saving Expert</a> and <a className='no-text-color' href='https://www.thetimes.co.uk/article/how-to-get-hot-theatre-tickets-for-less-than-a-fiver-9vdddzthz' target="_blank" rel='noreferrer'>The Times.</a>
                                                                              </p>
                                                                              <p>
                                                                                    Now that we are established, our vision has expanded and social responsibility is a key aspect of how we work.  Current projects include looking at how we can better identify and connect underserved groups with the arts and we are exploring <a className='no-text-color' href='https://www.bcorporation.net/' target="_blank" rel='noreferrer'>B-Corp</a> certification.
                                                                              </p>
                                                                              <p>
                                                                                    In addition to raising funds for <a className='no-text-color' href='https://nhscharitiestogether.co.uk/' target="_blank" rel='noreferrer'>NHS Charities Together</a> and investing in carbon avoidance projects for every theatre journey our members make via <a className='no-text-color' href='https://ecologi.com/centralticketslimited' target="_blank" rel='noreferrer'>Ecologi</a>, we’re also looking at ways we can support charitable organisations and groups within the communities we serve.
                                                                              </p>
                                                                              <p>
                                                                                    We’re always excited to welcome new members and work with new ticketing partners.  Our team are available 7 days a week.  Click <Link to={routesName.ContactUs} className='no-text-color' target="_blank" rel='noreferrer'>here</Link> to get in touch.
                                                                              </p>
                                                                              </> : <>
                                                                              
                                                                              <p>
                                                                                    Tickets for Medics is a seat filling agency specifically for NHS Staff & service providers with seat-filling opportunities for live events throughout the UK, created by the same team behind Central Tickets
                                                                              </p>
                                                                              <p>
                                                                                    By launching Tickets for Medics, we wanted to create a separate space where our ticketing partners can make their events available to a smaller group of verified NHS personnel and service providers.
                                                                              </p>
                                                                              <p>
                                                                                    By joining Tickets for Medics, or working with us as a Ticketing Partner you are helping to fund the work of over 230 NHS Charities across the UK, providing extra support needed to care for NHS staff, patients & communities.
                                                                              </p>
                                                                              <p>
                                                                                    In addition to raising funds for <a className='no-text-color' href='https://nhscharitiestogether.co.uk/' target="_blank" rel='noreferrer'>NHS Charities Together</a> and investing in carbon avoidance projects for every theatre journey our members make via <a className='no-text-color' href='https://ecologi.com/centralticketslimited' target="_blank" rel='noreferrer'>Ecologi</a>, we’re also looking at ways we can support charitable organisations and groups within the communities we serve.
                                                                              </p>
                                                                              <p>
                                                                                    We’re always excited to welcome new members and work with new ticketing partners. Our team are available 7 days a week. Click <Link to={routesName.ContactUs} className='no-text-color' target="_blank" rel='noreferrer'>here</Link> to get in touch.
                                                                              </p>
                                                                              </>}

                                                                        </div>
                                                                  </div>
                                                            </div>
                                                            {/* <!-- .content__body --> */}
                                                      </div>
                                                      {/* <!-- .content --> */}

                                                </div>
                                                {/* <!-- .col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}
                                    </div>
                                    {/* <!-- /.container-fluid  --> */}
                              </section>
                              {/* <!-- /#sectionAboutUs --> */}

                              <section data-scroll-section id="sectionEcologi" className="section__ecologi">
                                    <div data-scroll data-scroll-repeat data-inview="section__ecologi" className="container-fluid">
                                          <div className="row">
                                                <div className="col-md-12">

                                                      <div className="content content__ecologi text-center mx-auto">
                                                            <div className="content__header">
                                                                  <div className="big-heading">
                                                                        
                                                                              {screenWidth > 767 ?
                                                                                    <h2 className="mb-0">We are rising to the<br/>climate change challenge</h2>
                                                                                    :<h2 className="mb-0">We're rising to the challenge of climate change</h2>
                                                                              }
                                                                  </div>
                                                            </div>
                                                            {/* <!-- .content__header --> */}

                                                            <div className="content__body">
                                                                  <p className="lead fw-bold mx-auto">
                                                                        We believe in working in a way that is socially and environmentally sustainable which is why we've calculated average carbon emissions for every return journey our members make travelling to the theatre and invest the same amount into carbon avoidance schemes.  We do the same for the personal and working lives of our team ensuring that our operation and the people within it are part of a Climate Action Workforce. 
                                                                        <br/><br/>
                                                                        
                                                                        We do this from the admin fees we collect. The <a className='no-text-color' href='https://ecologi.com/projects' target="_blank" rel='noreferrer'>projects</a> we support are organised by Ecologi. Those are certified at the very highest level by <a className='no-text-color' href='https://ecologi.com/articles/blog/what-is-the-gold-standard-carbon-offset' target="_blank" rel='noreferrer'>Gold Standard</a> or the <a className='no-text-color' href='https://verra.org/programs/verified-carbon-standard/' target="_blank" rel='noreferrer'>Verified Carbon Standard</a>. Their funding strategy is guided by their <a className='no-text-color' href='https://ecologi.com/articles/in-depth/our-climate-committee' target="_blank" rel='noreferrer'>Climate Committee</a> and follows the <a className='no-text-color' href='https://drawdown.org/' target="_blank" rel='noreferrer'>Project Drawdown</a> framework.
                                                                  </p>

                                                                  <div className="features__impact justify-content-md-center" >
                                                                        <div data-scroll className="feature feature__impact" style={{margin:browserDetect ? "0 30px" : ""}}>
                                                                              <div className="feature__icon">
                                                                                    <ImageComponent className="icon icon- mx-auto" src="Icon_Planet_from_gio.svg" type="images"/>
                                                                              </div>
                                                                              <div className="feature__body">
                                                                                    <h4 className="heading">{month} YEARS</h4>
                                                                                    <p>climate action <br className="d-none d-md-block"/>workforce</p>
                                                                              </div>
                                                                        </div>
                                                                        {/* <!-- .feature --> */}
                                                                        <div data-scroll className="feature feature__impact" style={{margin:browserDetect ? "0 30px" : ""}}>
                                                                              <div className="feature__icon">
                                                                                    <ImageComponent className="icon icon- mx-auto" src="Icon_CO2_from_gio.svg" type="images"/>
                                                                              </div>
                                                                              <div className="feature__body">
                                                                                    <h4 className="heading">{carbonOffset}</h4>
                                                                                    <p>tCO2e <br className="d-md-block"/>avoided</p>
                                                                              </div>
                                                                        </div>
                                                                        {/* <!-- .feature --> */}
                                                                        <div data-scroll className="feature feature__impact" style={{margin:browserDetect ? "0 30px" : ""}}>
                                                                              <div className="feature__icon">
                                                                                    <ImageComponent className="icon icon- mx-auto" src="Icon_plant_from_gio.svg" type="images"/>
                                                                              </div>
                                                                              <div className="feature__body">
                                                                                    <h4 className="heading">{trees}</h4>
                                                                                    <p>trees <br className="d-md-block"/>planted</p>
                                                                              </div>
                                                                        </div>
                                                                        {/* <!-- .feature --> */}
                                                                  </div>
                                                                  {/* <!-- .features__impact --> */}

                                                            </div>
                                                            {/* <!-- .content__body --> */}
                                                      </div>
                                                      {/* <!-- .content --> */}

                                                </div>
                                                {/* <!-- .col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}
                                    </div>
                                    {/* <!-- /.container-fluid  --> */}
                              </section>
                              {/* <!-- /#sectionEcologi --> */}

                              <section data-scroll-section id="sectionOurNumbers" className="section__our-numbers">
                                    <div data-scroll data-scroll-repeat data-inview="section__our-numbers" className="container-fluid">
                                          <div className="row">
                                                <div className="col-md-12">

                                                      <div className="content content__our-numbers text-center mx-auto">
                                                            <div className="content__body">
                                                                  <h3 className="heading">Some of our numbers</h3>
                                                                  <ul className="list__stats flex-md-row flex-md-wrap mb-3">
                                                                        <li style={{margin:browserDetect ? "0 40px 40px" : ""}}>
                                                                              <div className="item">
                                                                                    <h4 className="item__heading">{ticketingPartners}</h4>
                                                                                    <div className="item__text">ticketing partners {domain=== "" ? "trust Central Tickets" : "trust our members"} <br/>for their audience needs.</div>
                                                                              </div>
                                                                        </li>
                                                                        <li style={{margin:browserDetect ? "0 40px 40px" : ""}}>
                                                                              <div className="item">
                                                                                    <h4 className="item__heading">{membersActivelyRecommend}</h4>
                                                                                    <div className="item__text">of members actively recommend <br/>shows to family/friends</div>
                                                                              </div>
                                                                        </li>
                                                                        <li style={{margin:browserDetect ? "0 40px 40px" : ""}}>
                                                                              <div className="item">
                                                                                    <h4 className="item__heading">{learnNewVenues}</h4>
                                                                                    <div className="item__text">of members learn of new venues <br/>& shows {domain==="" ? "via Central Tickets" : "via our platforms."}</div>
                                                                              </div>
                                                                        </li>
                                                                        <li style={{margin:browserDetect ? "0 40px 40px" : ""}}>
                                                                              <div className="item">
                                                                                    <h4 className="item__heading">{membersSpend}</h4>
                                                                                    {/* <div className="item__text">average spend per ticket booked</div> */}
                                                                                    <div className="item__text">average spend per ticket booked <br/>on snacks, drinks, programmes etc.</div>
                                                                              </div>
                                                                        </li>
                                                                        <li style={{margin:browserDetect ? "0 40px 40px" : ""}}>
                                                                              <div className="item">
                                                                                    <h4 className="item__heading">{membersMakePurchase}</h4>
                                                                                    <div className="item__text">of members make purchases <br className="d-xl-none"/>at the venue</div>
                                                                              </div>
                                                                        </li>
                                                                  </ul>
                                                            </div>
                                                            {/* <!-- .content__body --> */}
                                                      </div>
                                                      {/* <!-- .content --> */}

                                                </div>
                                                {/* <!-- .col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}
                                    </div>
                                    {/* <!-- /.container-fluid  --> */}
                              </section>
                              {/* <!-- /#sectionOurNumbers --> */}

                              <section data-scroll-section id="sectionOurValues" className="section__our-values pt-0">
                                    <div data-scroll data-scroll-repeat data-inview="section__our-values" className="container-fluid">
                                          <div className="row">
                                                <div className="col-md-12 col-extend-fw">
                                                      <div className="content content__our-values mx-auto border-top border-bottom">
                                                            <div className="content__header">
                                                                  <div className="big-heading text-center">
                                                                        <h1 className="mb-0">Our Values</h1>
                                                                        <HeadingPictureComponent 
                                                                        dataScroll
                                                                        className="ticket-blue"
                                                                        srcSet="ticket-about-big-heading3-blue.svg"
                                                                        src="ticket-about-big-heading3-blue-sm.svg"
                                                                        />

                                                                        <HeadingPictureComponent 
                                                                        dataScroll
                                                                        className="ticket-orange"
                                                                        srcSet="ticket-about-big-heading3-orange.svg"
                                                                        src="ticket-about-big-heading3-orange-sm.svg"
                                                                        />

                                                                        <HeadingPictureComponent 
                                                                        dataScroll
                                                                        className="ticket-green"
                                                                        srcSet="ticket-about-big-heading3-green.svg"
                                                                        src="ticket-about-big-heading3-green-sm.svg"
                                                                        />

                                                                        <HeadingPictureComponent 
                                                                        dataScroll
                                                                        className="ticket-yellow"
                                                                        srcSet="ticket-about-big-heading3-yellow.svg"
                                                                        src="ticket-about-big-heading3-yellow-sm.svg"
                                                                        />

                                                                        <picture>
                                                                              {/* <img data-scroll className="ticket ticket-aquamarine d-none d-lg-block" src="../../assets/images/tickets/ticket-about-big-heading3-aquamarine.svg"  alt=""/> */}

                                                                              <ImageComponent dataScroll className="ticket ticket-aquamarine d-none d-lg-block" src="ticket-about-big-heading3-aquamarine.svg" type="tickets"/>
                                                                        </picture>
                                                                  </div>
                                                            </div>
                                                            {/* <!-- .content__header --> */}
                                                      </div>
                                                </div>
                                                {/* <!-- .col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}
                                          <div className="row">
                                                <div className="col-md-12">
                                                      <div className="content content__list-values mx-auto">
                                                            <div className="content__body">
                                                                  <ul className="list__values list-unstyled mb-0">
                                                                        <li>
                                                                              <div className="item d-flex">
                                                                                    <div className="item__number flex-shrink-0">
                                                                                          <div className="number bg-lime">1</div>
                                                                                    </div>
                                                                                    <div className="item__text">
                                                                                          <h3>Value people & ideas</h3>
                                                                                          <p>We value every single member, client and team-mate.  We recognise that everyone plays an important part in what we do.  Every idea is welcomed and every great one is implemented.</p>
                                                                                    </div>
                                                                              </div>
                                                                        </li>
                                                                        <li>
                                                                              <div className="item d-flex">
                                                                                    <div className="item__number flex-shrink-0">
                                                                                          <div className="number bg-mustard">2</div>
                                                                                    </div>
                                                                                    <div className="item__text">
                                                                                          <h3>Work sustainably</h3>
                                                                                          <p>We believe in using our platform to provide a friendly, inclusive service that connects underserved groups with the arts whilst achieving positive social impact.</p>
                                                                                    </div>
                                                                              </div>
                                                                        </li>
                                                                        <li>
                                                                              <div className="item d-flex">
                                                                                    <div className="item__number flex-shrink-0">
                                                                                          <div className="number bg-avocado ">3</div>
                                                                                    </div>
                                                                                    <div className="item__text">
                                                                                          <h3>Solve problems</h3>
                                                                                          <p>Being dynamic and responsive to the changing landscape of live performance and innovating our platform to meet those needs, is our cultural norm.</p>
                                                                                    </div>
                                                                              </div>
                                                                        </li>
                                                                        <li>
                                                                              <div className="item d-flex">
                                                                                    <div className="item__number flex-shrink-0">
                                                                                    <div className="number bg-cobalt">4</div>
                                                                                    </div>
                                                                                    <div className="item__text">
                                                                                    <h3>Be consistent</h3>
                                                                                    <p>We strive to maintain a good reputation for delivering good results with minimal fuss.  Having smooth systems and being reliable is important to us.</p>
                                                                                    </div>
                                                                              </div>
                                                                        </li>
                                                                        <li>
                                                                              <div className="item d-flex">
                                                                                    <div className="item__number flex-shrink-0">
                                                                                    <div className="number bg-turquoise">5</div>
                                                                                    </div>
                                                                                    <div className="item__text">
                                                                                    <h3>Be accountable</h3>
                                                                                    <p>We exist to serve our members and the event organisers we work with.  If we could have done things better, we’ll take responsibility, put things right and put a new system in place.</p>
                                                                                    </div>
                                                                              </div>
                                                                        </li>
                                                                  </ul>
                                                            </div>
                                                            {/* <!-- .content__body --> */}
                                                      </div>
                                                      {/* <!-- .content --> */}
                                                </div>
                                                {/* <!-- .col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}
                                    </div>
                                    {/* <!-- /.container-fluid  --> */}
                              </section>
                              {/* <!-- /#sectionOurValues --> */}

                              <section data-scroll-section id="sectionListingShowSteps" className="section__listing-show-steps">
                                    <div data-scroll data-scroll-repeat data-inview="section__listing-show-steps" className="container-fluid">
                                          <div className="row">
                                                <div className="col-md-12">

                                                      <div className="content content__listing-show-steps text-center mx-auto">
                                                            <div className="content__header">
                                                                  <div className="big-heading">
                                                                        <h2 className="mb-0">Partner with us by listing a show in a few easy steps.<br/></h2>
                                                                  </div>
                                                            </div>
                                                            {/* <!-- .content__header --> */}
                                                            <div className="content__body">
                                                                  <p className="lead fw-bold">
                                                                        Send us your events details (dates, times, image, copy and YouTube link).  
                                                                  </p>
                                                                  <p className="lead fw-bold">
                                                                        Share any specific fulfilment needs (barcodes/eTickets etc)
                                                                  </p>
                                                                  <div className="icon-block icon-block__arrow">
                                                                        <ImageComponent src="icon-arrow-down-bordered-circle-black-lg.svg" type="images"/>
                                                                  </div>
                                                                  <p className="lead fw-bold">
                                                                        Finally, let us know how many tickets you’d like to fill.
                                                                  </p>
                                                                  <p className="lead fw-bold">
                                                                        {/* Once we go live, you’ll get a <span className="red">live tracking link</span> to monitor bookings <br/>in real-time. */}
                                                                        We’ll go live and you’ll receive a <span className="red">tracking link</span> to monitor bookings <br/>in real-time.
                                                                  </p>
                                                                  <div className="icon-block icon-block__arrow">
                                                                        <ImageComponent src="icon-arrow-down-bordered-circle-black-lg.svg" type="images"/>
                                                                  </div>
                                                                  <p className="lead fw-bold">
                                                                        We’ll send guest-lists a few hours ahead of curtain up.
                                                                  </p>
                                                                  <p className="lead fw-bold">
                                                                        Just let us know who we should loop into that email.
                                                                  </p>
                                                                  <div className="icon-block icon-block__arrow">
                                                                        <ImageComponent src="icon-arrow-down-bordered-circle-black-lg.svg" type="images"/>
                                                                  </div>
                                                                  {/* <p className="lead fw-bold">
                                                                        Get started <Link to={routesName.ContactUs} state={{ organiser : true}}>here</Link>
                                                                  </p> */}
                                                                  <div className="buttons-wrapper justify-content-center">
                                                                        <Link className="btn btn-xl-lg btn-primary" to={routesName.ContactUs} rel="noreferrer" target="_blank">Get started here</Link>
                                                                  </div>

                                                                  <div className="big-heading big-heading__bottom">
                                                                        <h2 className="mb-0">We look forward <br className="d-none d-lg-block"/>to working with you!</h2>
                                                                  </div>
                                                            </div>
                                                            {/* <!-- .content__body --> */}
                                                      </div>
                                                      {/* <!-- .content --> */}

                                                </div>
                                                {/* <!-- .col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}
                                    </div>
                                    {/* <!-- /.container-fluid  --> */}
                              </section>
                              {/* <!-- /#sectionListingShowSteps --> */}

                              <section data-scroll-section id="sectionThanks" className="section__thanks-to-supporters bg-red border-bottom">
                                    {/* <!--
                                          NOTE: class "container__block" removes child row paddings and negative margins; and col-* paddings
                                    --> */}
                                    <div data-scroll data-scroll-repeat data-inview="section__thanks-to-supporters" className="container-fluid">
                                          <div className="row">
                                                <div className="col-md-12">

                                                      <div className="content content__thanks-to-supporters text-center mx-auto">
                                                            <div className="content__header">
                                                                  <div className="big-heading">
                                                                        {/* <h2 className="mb-0">A huge thanks to <br className="d-none d-lg-block"/>all our supporters</h2> */}
                                                                        <h2 className="mb-0">
                                                                              A big thank you to our members who raised £47K to support the arts during lockdown!
                                                                        </h2>
                                                                  </div>
                                                            </div>
                                                            {/* <!-- .content__header --> */}
                                                            <div className="content__cta">
                                                                  <a className="btn btn-xl-lg btn-primary bg-white" href="https://www.crowdfunder.co.uk/p/centraltickets" target="_blank" rel='noreferrer'>Find out more here</a>
                                                            </div>

                                                            
                                                            {/* <!-- .content__cta --> */}
                                                      </div>
                                                      {/* <!-- .content --> */}

                                                </div>
                                                {/* <!-- .col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}
                                    </div>
                                    {/* <!-- /.container-fluid  --> */}
                              </section>
                              {/* <!-- /#sectionThanks --> */}

                              <div data-scroll-section className="bg-white border-bottom">
                                    <TickerText id="tickerShowCatsOne" textData={catsOneText}/>
                              </div>

                        </main>

                        <Footer />
                  </div>
                  {/* <!-- /div[data-scroll-container] --> */}
            </React.Fragment>
      )
}

export default AboutUs;
