import React, { useEffect, useState } from 'react';

// external css
// redux and react hooks
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// child component
// packages

//Added by PP
import { useImageSize } from "react-image-size";

// Actions & RTK query or mutations
import { useLazyDownloadTicketsQuery } from '../Services/modules/Bookings';
import { useLazyGetCollectionInstructionQuery } from '../Services/modules/Others';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { setAlert } from '../Store/UI';
import { setCancleBookings } from '../Store/CurruntBookings';
import { setShowDetails, setStep, setUserType } from '../Store/ContactUs';

// Constants
import { dateFormathandler, timeFormatHandler } from "../resources/DateFormatForCurrBookings";
import { errors, messages } from '../resources/en';
import { NameConstants } from '../resources/NameConstants';
import { routesName } from '../resources/RoutesName';

const MyBookingItem = (props) => {
      
      const [displayCol,setDisplayCol] = useState(false)
      const [downloadTickets, { data, isSuccess, error, isLoading }]  =    useLazyDownloadTicketsQuery();
      const [getCollectionInstruction, { data : colData, isSuccess : colIsSuccess, error : colError, isLoading : colIsLoading }]  =    useLazyGetCollectionInstructionQuery();

      const browserDetect = useSelector(state=>state.ui.browserDetect);
      const dispatch = useDispatch()
      const navigate = useNavigate()

      const [dimensions] = useImageSize(props.blur_event_image);
      // const [dimensions, { loadingpp, errorpp }] = useImageSize(props.blur_event_image);

      useEffect(()=>{
            if(isSuccess) {
                  if(data.status === "1"){
                        window.open(data.download_link, "_blank") || window.location.replace(data.download_link);

                  } else if((data.status === "0")) {
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.error || data.errors || data.message || errors.NotFound404}));
                  }
            }

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404}));
            }
      
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[data, isSuccess, error, isLoading, dispatch ])


      useEffect(()=>{
            if(colIsSuccess) {
                  if(colData.status === "1"){
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:colData.collection_instruction || errors.NotFound404}));
                        
                  } else if((colData.status === "0")) {
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:colData.error || colData.errors || colData.message || errors.NotFound404}));
                  }
                  setDisplayCol(false)
            }

            if(colError){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:colError.error || colError.data.error || colError.data.message || errors.NotFound404}));
            }
      
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[colData, colIsSuccess, colError, colIsLoading, dispatch, displayCol])

      const collectionInstructionHandler = ()=>{
            
            if(!colIsLoading){
                  setDisplayCol(true)
                  getCollectionInstruction({
                        "booking_id": props.booking_id,
                        "schedule_id":props.show_id
                  })
            }
      }

      const manageBookinghandler = ()=> {
            
            if(props.vendor === "TTG" || props.vendor === "ING") {
                  const { afterLoad, ...rest} = props
                  dispatch(setShowDetails({showDetails : rest}))
                  dispatch(setStep({step : NameConstants.MemberEventDetail }));
                  dispatch(setUserType({ userType : NameConstants.Member}))
                  navigate(routesName.ContactUs)
                  
            } else {

                  dispatch(setCancleBookings({
                        isCancling : true,
                        eventType : props.group_availablity,
                        // tickets : props.group_availablity === "2" ? 0 : props.tickets,
                        tickets : props.tickets,
                        step : 'step1',
                        booking_id : props.booking_id,
                        event_id : props.event_id,
                        bookingRef : props.BookingRef,
                        start_time : props.start_time,
                        start_date : props.start_date,
                        event_title : props.event_name
                  }))
            }
      }

      const downloadTicketHandler = ()=>{
            if(data){
                  window.open(data.download_link, "_blank") || window.location.replace(data.download_link);
            } else {
                  downloadTickets({booking_id : props.booking_id})
            }
      }

      const ttgOrIngHandler = ()=>{
            dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:"This is a third-party booking made via Encore/Today Tix Group. Please contact us if you need help."}))
      }

      const startDateArray = props.start_date.split("-");
      const startDate =  startDateArray[1] + "-"+startDateArray[0] +"-" + startDateArray[2]


      //Added by pp
      const this_aspect_ratio = dimensions?.width / dimensions?.height;
      let encore_vertical_image = "true";
      if (this_aspect_ratio > 1) {
            encore_vertical_image = "false";
      }
      //Added by PP

      return (
            <li>
                  <div className="card card__event flex-row flex-wrap mx-auto">
                        <div className="card__event-image">
                              <div className={encore_vertical_image === "true" ? "ttg-image-wrapper" : ""} 
                                    style={{backgroundImage : encore_vertical_image === "true" ? `Url(${props.blur_event_image})` : "",}}>
                                    <div>
                                          <LazyLoadImage className="event-image" 
                                                src={props.event_image} 
                                                srcSet={`${props.event_image} 2x`} 
                                                afterLoad={props.afterLoad} 
                                                effect={encore_vertical_image === "true" ? "blur" : ""}
                                                />
                                    </div>
                              </div>
                        </div>
                        <div className="card-body py-0">
                              <h5 className="card-title fw-bold">
                                    <div>{props.event_name}</div>
                              </h5>
                              <ul className="list__event-details list-unstyled">
                                    <li>{props.tickets} {props.tickets > 1 ? "Tickets" : "Ticket"}</li>
                                    <li>{props.date_time_formated}</li>
                                    {/* <li>{dateFormathandler(startDate)  +' – '+ timeFormatHandler(props.start_time) }</li> */}
                                    <li>{props.Location}</li>
                              </ul>
                        </div>
                        {/* <!-- /.card-body --> */}
                        <div className="card-footer ms-lg-auto">
                              <div className={["buttons-wrapper flex-wrap",props.type === "past" ? "my-lg-auto" : ""].join(" ")}>
                                    {props.type !== "past" ?
                                    <>
                                          {props.vendor === "TTG" ?  
                                          <button type="button" className="btn btn-primary btn-view-ticket order-1" style={{marginBottom:browserDetect ? "10px":""}} onClick={downloadTicketHandler} disabled={isLoading}>
                                                Download Tickets
                                          </button>
                                          :
                                          <button type="button" className="btn btn-primary btn-view-ticket order-1" style={{marginBottom:browserDetect ? "10px":""}}  onClick={downloadTicketHandler} disabled={isLoading}>
                                                Download Tickets
                                          </button>
                                          }
                                          <button type="button" className="btn btn-primary btn-manage-booking order-2 order-lg-3" 
                                          disabled={props.vendor === "TTG" || props.vendor === "ING" || props.vendor === "LTD"} 
                                          onClick={manageBookinghandler} style={{marginBottom:browserDetect ? "10px":""}} >
                                                Manage
                                          </button>
                                          <button type="button" className="btn btn-primary order-3 order-lg-2" disabled={props.vendor === "TTG" || props.vendor === "ING" ||props.vendor === "LTD" || colIsLoading} onClick={collectionInstructionHandler} style={{marginBottom:browserDetect ? "10px":""}}>Collection Instructions</button>
                                    </> :
                                    <div className="buttons-wrapper flex-wrap my-lg-auto" >
                                          {(props.vendor !== "TTG" && props.vendor !== "ING" && props.vendor !== "LTD") ?
                                                <>
                                                <button type="button" className="btn btn-primary order-3 order-lg-2" disabled={colIsLoading} onClick={collectionInstructionHandler} style={{marginBottom:browserDetect ? "10px": "" }}>Collection Instructions</button>
                                                {props.is_feedback_left !== true && <Link to={`/leave-feedback/${props.booking_id}`} state={{ event_id : props.event_id }} className="btn btn-primary" >
                                                      Leave Feedback
                                                </Link>}
                                                {(props.is_feedback_left === true || props.is_feedback_left === "true" ) && <div className="btn btn-primary feedback-left">
                                                      Feedback Left
                                                </div>}
                                                </> :
                                                <>
                                                <button type="button" className="btn btn-primary order-3 order-lg-2" onClick={ttgOrIngHandler} style={{marginBottom:browserDetect ? "10px": "" }}>Collection Instructions</button>
                                                {/* to disable look for button */}
                                                {/* <button className="btn btn-primary feedback-left" onClick={ttgOrIngHandler}> */}
                                                <button className="btn btn-primary" onClick={ttgOrIngHandler}>
                                                      Leave Feedback
                                                </button>
                                          </>}
                                    </div>}
                              </div>
                        </div>
                        {/* <!-- /.event-cta --> */}
                  </div>
                  {/* <!-- /.card --> */}
            </li>
      )
}

export default MyBookingItem
