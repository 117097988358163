import React, { useState, useEffect } from 'react'
// external css
// redux and react hooks
import { useSelector, useDispatch } from 'react-redux';

// child component
// packages
import DatePicker from "react-datepicker";
import { osName  } from "react-device-detect";


// Actions & RTK query or mutations
import { useWidgetSessionMutation } from '../../../Services/modules/GiftVoucher';
import { setSpinner } from '../../../Store/GiftVoucher';
import { setAlert } from '../../../Store/UI';
// Constants
import { actions, label, placeholders, messages, errors } from '../../../resources/en';
import { NameConstants } from '../../../resources/NameConstants';
import { giftVoucherValidationRules } from '../../../Services';
import { setGiftParams } from '../../../Store/GiftVoucher';

const initialError = {error:false,errorMessage:""}

const Step1 = (props) => {

      const [state,setState]= useState({
            [NameConstants.TheirFirstName]:{value:"",error:false,errorMessage:""},
            [NameConstants.TheirLastName]:{value:"",error:false,errorMessage:""},
            [NameConstants.TheirEmail]:{value:"",error:false,errorMessage:""},
            [NameConstants.TheirMessage]:{value:"",error:false,errorMessage:""},
      })

      const giftVoucher                         =     useSelector(state=>state.giftVoucher);

      const [giftAmount,setGiftAmount]          =     useState("")
      const [startDate, setStartDate]           =     useState(null);
      const [error, setError]                   =     useState(initialError)
      const [dateError, setDateError]           =     useState({error: false, errorMessage :""})

      const dispatch                            =     useDispatch()

      const [createVoucherSession, { data, error : sessionError , isLoading }]   =     useWidgetSessionMutation()

      useEffect(()=>{
            setGiftAmount(giftVoucher.giftAmount)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

      useEffect(()=>{
            if(data) {
                  dispatch(setSpinner({status:""}));
                  if (data.status === "1") {
                        dispatch(setGiftParams({
                              isTopUpOpen: true,
                              step : "step2",
                              paymentMethod : "",
                              giftAmount : giftAmount,
                              voucherSessionId : data.data.session_id
                        }))
                  } else if(data.status === "0") {
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.error || data.message || errors.NotFound404}));
                  }
                  else {
                        const errors = data?.data?.errors
                        let giftAmountError = "";
                        let firstNameError = "";
                        let lastNmaeError = "";
                        let dateError = ""
                        let emailError = "";
                        let messageError = ""

                        errors.forEach(error => {
                              if(error.field_name === "voucher_fname") {
                                    firstNameError = error.message
                              }
                              else if(error.field_name === "voucher_lname") {
                                    lastNmaeError = error.message
                              }
                              else if(error.field_name === "voucher_date") {
                                    dateError = error.message
                              }
                              else if(error.field_name === "voucher_email") {
                                    emailError = error.message
                              }
                              else if(error.field_name === "voucher_amount") {
                                    giftAmountError = error.message
                              } 
                              else if(error.field_name === "voucher_message") {
                                    messageError = error.message
                              }
                        });

                        setState(prevState=>{

                              return {
                                    ...prevState,
                                    [NameConstants.TheirFirstName]:{...prevState[NameConstants.TheirFirstName],error:firstNameError.length > 0, errorMessage: firstNameError},
                                    [NameConstants.TheirLastName]:{...prevState[NameConstants.TheirLastName],error:lastNmaeError.length > 0, errorMessage: lastNmaeError},
                                    [NameConstants.TheirEmail]:{...prevState[NameConstants.TheirEmail],error:emailError.length > 0, errorMessage: emailError},
                                    [NameConstants.TheirMessage]:{...prevState[NameConstants.TheirMessage],error:messageError.length> 0, errorMessage: messageError},
                              }
                        })

                        if(giftAmountError.length > 0) {
                              setError(prevState=>{
                                    return {
                                          ...prevState,
                                          error:true,
                                          errorMessage:giftAmountError
                                    }
                              })
                        }

                        if(dateError.length > 0) {
                              setDateError({error: true, errorMessage : dateError})
                        }
                        // dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.error || data.message || errors.NotFound404}));
                  }
            }

            if(sessionError) {
                  dispatch(setSpinner({status:""}));
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:sessionError.error || sessionError.data.error || sessionError.data.message || errors.NotFound404}));
            }
            
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[data, sessionError])

      const chnageStartDateHandler = (date)=>{
            if(dateError.error) {
                  setDateError({error: false, errorMessage : ""})
            }

            const now = new Date();
            if (date.getTime() < now.getTime()) {
                  setStartDate(now)
                  return
            }

            setStartDate(date)
      }

      const changeGiftAmountHandler = (event)=>{
            let val = validate(event.target.value) ? validate(event.target.value).input : ""

            if (val.charAt(0) === '.') {
                  val = val.replace('.', '0.');
            }

            // Remove all digits except for two digits after the decimal point
            if(val.includes(".")){
                  let index = val.indexOf('.');
                  if (index !== -1) {
                        val = val.substring(0, index + 3); // str is now "2.22"
                  }
            }

            setGiftAmount(val)
      }

      const amountHandler = ()=>{

            if(giftAmount >= 1 && giftAmount < 1000) {
                  dispatch(setGiftParams({
                        isTopUpOpen: true,
                        step : "step1",
                        paymentMethod : "",
                        giftAmount : giftAmount
                  }))

            } else {
                  
                  let message = errors.giftAmount.enterAmount
                  if(giftAmount >= 1000) {
                        message =  errors.giftAmount.excedAmountLimit
                  }
                  setError(prevState=>{
                        return {
                              ...prevState,
                              error:true,
                              errorMessage:message
                        }
                  })
            }
      }

      const changeHandler = (event)=>{

            if(giftAmount < 1 || giftAmount > 1000) {
                  let message = giftAmount < 1 ? errors.giftAmount.enterAmount : errors.giftAmount.excedAmountLimit
                  setError(prevState=>{
                        return {
                              ...prevState,
                              error:true,
                              errorMessage:message
                        }
                  })
                  return
            }

            const name = event.target.name;
            let value = event.target.value;

            if(name === NameConstants.TheirFirstName || name === NameConstants.TheirLastName){
                  value = capitalizeFirstLetter(value).trim();
            }

            setState(prevState=>{
                  return {
                        ...prevState,
                        [name]:{...prevState[name],value:value}
                  }
            })

            

      }

      function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
      }

      const focusHandler = ()=> {
            setError(initialError);
      }

      const validate = (s) => {
            var rgx = /^[0-9]*\.?[0-9]*$/;
            return s.match(rgx);
      }

      const onfocusHandler = () => {
            setState(prevState=>{

                  return {
                        ...prevState,
                        [NameConstants.TheirFirstName]:{...prevState[NameConstants.TheirFirstName],error:false},
                        [NameConstants.TheirLastName]:{...prevState[NameConstants.TheirLastName],error:false},
                        [NameConstants.TheirEmail]:{...prevState[NameConstants.TheirEmail],error:false},
                        [NameConstants.TheirMessage]:{...prevState[NameConstants.TheirMessage],error:false},
                  }
            })

            setDateError({error : false, errorMessage : ""})

            setError(prevState=>{
                  return {
                        ...prevState,
                        error:false,
                        errorMessage:""
                  }
            })
      }

      const formateDate = (date)=> {

            let finalStartDate = ""
            let formattedTime = ""
            const startDate = new Date(date.toString())
            if(startDate !==null){
                  const start_dd        = ("0"+ startDate.getDate()).slice(-2);
                  const start_mm        = ("0"+(startDate.getMonth() + 1)).slice(-2);
                  const start_yyyy      = startDate.getFullYear();
      
                  const hours = startDate.getHours();
                  const minutes = startDate.getMinutes();
                  const seconds = startDate.getSeconds();
                  finalStartDate = start_yyyy+"-"+start_mm+"-"+start_dd;
      
                  formattedTime = `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;
      
            }

            return finalStartDate+ " " +formattedTime
      }

      // Function to pad single-digit numbers with leading zeros
      function padZero(num) {
            return num < 10 ? `0${num}` : num;
      }

      const stepChangeHandler = (event) => {
            event.preventDefault()
           

            if(giftAmount >= 1 && giftAmount < 1001) {
                  const errorStore = [];
                  const copy = {
      
                  }
      
                  for(let key in state) {
                        const errorCheck = giftVoucherValidationRules(state[key].value, key)
      
                        if(!errorCheck.isValid) {
                              errorStore.push(key)
                        }
      
                        copy[key] = {...state[key],error:!errorCheck.isValid,errorMessage:errorCheck.validationMessage}
      
                  }
                  
                  if(!startDate) {
                        setDateError({error : true, errorMessage : errors.selectDate})
                  }

                  if(errorStore.length > 0) {
                        setState(prevState=>{
                              return {
                                    ...prevState,
                                    ...copy
                              }
                        })
                  }
                  else if(!dateError.error) {
                        const data = {
                              action: "voucher",
                              voucher_fname: state[NameConstants.TheirFirstName].value,
                              voucher_lname: state[NameConstants.TheirLastName].value,
                              voucher_email: state[NameConstants.TheirEmail].value,
                              voucher_date: formateDate(startDate.toString()),
                              voucher_message : state[NameConstants.TheirMessage].value,
                              voucher_amount : giftAmount,
                        }

                        dispatch(setSpinner({status:"request"}));
                        createVoucherSession(data)
                  }

            } else {
                  let message = giftAmount < 1 ? errors.giftAmount.enterAmount : errors.giftAmount.excedAmountLimit
                  setError(prevState=>{
                        return {
                              ...prevState,
                              error:true,
                              errorMessage:message
                        }
                  })
            }
      }

      const backStep1Handler = ()=> {
            dispatch(setGiftParams({
                  isTopUpOpen: false,
                  step : "step1",
                  paymentMethod : "",
                  giftAmount : "",
                  userDetails : null
            }))
      }


      const isToday = startDate && startDate.toDateString() === new Date().toDateString();
      // If today, set minTime to now; if not, set minTime to midnight
      const minTime = isToday ? new Date() : new Date(new Date().setHours(0, 0, 0, 0));
      // Always allow times up to the end of the day
      const maxTime = new Date(new Date().setHours(23, 59, 59, 999));


      return (
            <div id="walletTopupStep1" className="content content__payment-options content__topup-options border-bottom">
                  <div className="content__body">

                        <h4 className="heading">Enter the amount you’d like to gift:</h4>
                        <div className={["form-group-wrapper d-flex flex-column flex-md-row two-cols-md gap-4",'error' ? "for_flexi_error_design" : ""].join(" ")}>
                              <div className="input-group input-group__amount input-group__as-form-control">
                                    <span className="input-group-text fw-bold">£</span>
                                    <input id="inputTopupAmount" type="text" className="form-control fc__amount fw-bold" 
                                          value={giftAmount || ""} 
                                          onFocus={focusHandler}
                                          onChange={changeGiftAmountHandler} 
                                          onBlur={amountHandler}
                                          placeholder="0.00"
                                    />
                              </div>
                              {error.error && <p className="form-label-error flexi_error_mobile">{error.errorMessage}</p>}
                              {/* <button id="btnEnterTopupAmount" type="button" className="btn btn-primary align-self-md-center" 
                              onClick={amountHandler}
                              disabled={enterAmount}
                              style={{marginleft:browserDetect ? "20px":""}}>
                                    Enter
                              </button> */}
                        </div>
                        {error.error && <p className="form-label-error flexi_error_device">{error.errorMessage}</p>}
                        
                        <hr/>
                        <h4 className="heading">Recipient details</h4>
                        <div className={["form-groups__use-new-card",osName ==="iOS"  ? "ios-body-padding" : "general-body-padding"].join(" ")} >
                                    <div className="form-group d-flex">
                                          <div className="form-group flex-1">
                                                <label htmlFor="registerInputFirstName" className="form-label">{label[NameConstants.TheirFirstName]}</label>
                                                <input type="text" 
                                                className="form-control" id="walletTopupInputCardName" 
                                                name={NameConstants.TheirFirstName} 
                                                placeholder={placeholders[NameConstants.TheirFirstName]}
                                                onFocus={onfocusHandler}
                                                onChange={changeHandler}
                                                value={state[NameConstants.TheirFirstName].value}
                                                // disabled={!enterAmount}
                                                />
                                                {state[NameConstants.TheirFirstName].error && <p className="form-label-error">{state[NameConstants.TheirFirstName].errorMessage}</p>}
                                          </div>
                                          <div className="form-group flex-1">
                                                <label htmlFor="registerInputFirstName" className="form-label">{label[NameConstants.TheirLastName]}</label>
                                                <input type="text" className="form-control" id="walletTopupInputCardName"
                                                 name={NameConstants.TheirLastName} 
                                                 placeholder={placeholders[NameConstants.TheirLastName]}
                                                 onFocus={onfocusHandler}
                                                 onChange={changeHandler}
                                                 value={state[NameConstants.TheirLastName].value}
                                                //  disabled={!enterAmount}
                                                />
                                                {state[NameConstants.TheirLastName].error && <p className="form-label-error">{state[NameConstants.TheirLastName].errorMessage}</p>}
                                          </div>
                                    </div>
                                    <div className="form-group">
                                          <label htmlFor="registerInputFirstName" className="form-label">{label[NameConstants.TheirEmail]}</label>
                                          <input type="text" className="form-control" id="walletTopupInputCardName"
                                          name={NameConstants.TheirEmail}
                                          placeholder={placeholders[NameConstants.TheirEmail]}
                                          onFocus={onfocusHandler}
                                          onChange={changeHandler}
                                          value={state[NameConstants.TheirEmail].value} 
                                          // disabled={!enterAmount}
                                          />
                                          {state[NameConstants.TheirEmail].error && <p className="form-label-error">{state[NameConstants.TheirEmail].errorMessage}</p>}
                                    </div>
                                    <div className="form-group">
                                                <label htmlFor="registerInputFirstName" className="form-label">Date you’d like us to deliver the voucher credit</label>
                                          <div className={["form-datepicker", "right-space" ].join(" ")}>
                                                
                                                <DatePicker selected={startDate} className="form-control__datepicker datepicker-xl-lg"  
                                                      onChange={(date:Date) => chnageStartDateHandler(date)} 
                                                      dateFormat="dd-MM-yyyy HH:mm" 
                                                      placeholderText="Click to Select" 
                                                      minDate={new Date()}
                                                      showTimeSelect
                                                      timeIntervals={15}
                                                      minTime={minTime}
                                                      maxTime={maxTime}
                                                      // timeFormat="HH:mm:ss"
                                                      timeFormat="HH:mm"
                                                />
                                          </div>
                                          {dateError.error && <p className="form-label-error">{dateError.errorMessage}</p>}
                                    </div>
                                    <div className="form-group">
                                          <label htmlFor="eventOrgContactMessage" className="form-label">{label[NameConstants.TheirMessage]}</label>
                                          <textarea className="form-control" id="eventOrgContactMessage" 
                                          name={NameConstants.TheirMessage}
                                          placeholder={placeholders[NameConstants.TheirMessage]}
                                          onFocus={onfocusHandler}
                                          onChange={changeHandler}
                                          maxLength="200"
                                          value={state[NameConstants.TheirMessage].value}
                                          // disabled={!enterAmount}
                                          ></textarea>
                                          {state[NameConstants.TheirMessage].error && <p className="form-label-error">{state[NameConstants.TheirMessage].errorMessage}</p>}
                                    </div>
                              </div>
                        
                  </div>
                  {/* <!-- /.content__body --> */}
                  <div className="giftVoucher-lower-footer-body">
                        <div className="content__body content__body--total border-top">
                              <div className="subtotal-block">
                                    <div className="fw-bold">Total (VAT inc.):</div>
                                    <div className="total-amount">£{parseFloat(giftAmount || 0).toFixed(2)}</div>
                              </div>
                        </div>
                        <div className="content__footer border-top">
                              <div className="buttons-wrapper gap-0">
                                    <button id="btnBackToStep1" type="button" className="btn__back btn btn-outline-primary w-50" onClick={backStep1Handler}>{actions.back}</button>
                                    <button id="btnWalletTopUp" type="button" className="btn btn-primary w-50" disabled={isLoading} onClick={stepChangeHandler}>{actions.next}</button>
                              </div>
                        </div>
                        {/* <!-- /.content__body --> */}
                  </div>
            </div>
      )
}

export default Step1
