import React, { useState, useEffect } from 'react'
// external css
// redux and react hooks
import { useSelector, useDispatch } from 'react-redux';

// child component
// packages
import {PaymentRequestButtonElement, useStripe} from '@stripe/react-stripe-js';


// Actions & RTK query or mutations
import { useFetchApplePayIntentMutation, useWalletWebhookConfirmationMutation } from '../../../Services/modules/Wallet';
import { setWalletParams, setTermsModal } from '../../../Store/Wallet';
import { setWalletBalance } from '../../../Store/User';
import { setAlert, setSpinner } from '../../../Store/UI';

// Constants
import { errors, messages } from '../../../resources/en';
import { routesName } from '../../../resources/RoutesName';
import { URL } from '../../../resources/Url';
import { Config } from '../../../Config';

const initialError = {error:false,errorMessage:""}

let spinnerTimeOut;

const Step1 = () => {

      const [topUp,setTopUp]                    =     useState("");
      const [error,setError]                    =     useState(initialError)
      const [enterAmount,setEnterAmount]        =     useState(false)
      const browserDetect                       =     useSelector(state=>state.ui.browserDetect)
      const wallet                              =     useSelector(state=>state.wallet)
      const {ct_web_view_session, domain}       =     useSelector(state=>state.user)

      const dispatch                            =     useDispatch()
      const stripe                              =     useStripe()
      const [paymentRequest, setPaymentRequest] =     useState(null)

      const [fetchApplePayIntent, { data : IntData, isSuccess : IntIsSuccess, error : IntError, isLoading : IntIsLoading  }] = useFetchApplePayIntentMutation();
      const [walletWebhookConfirmation, { data : WebData, isSuccess : WebIsSuccess, error : WebError, isLoading : WebIsLoading  }] = useWalletWebhookConfirmationMutation();

      useEffect(()=> {

            if(wallet.topUpAmount > 0 && paymentRequest) {
                  // setCallIntent(false)
                  dispatch(setSpinner({status:"request"}));

                  let paymentIntent = null;
                  if(IntData) {
                        paymentIntent = IntData.payment_intent ? IntData.payment_intent : ""
                  }
                  fetchApplePayIntent({
                        amount : parseFloat(wallet.topUpAmount * 100),
                        IntentId : paymentIntent ? paymentIntent : null ,
                        source:"wallet",
                        session_id : ct_web_view_session ? ct_web_view_session : "",
                  })
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[fetchApplePayIntent,paymentRequest])

      useEffect(()=>
      {
            if(IntIsSuccess) {
                  clearTimeout(spinnerTimeOut)
                  spinnerTimeOut = setTimeout(()=>{
                        dispatch(setSpinner({status:""}));
                  },[1000])
                  if(IntData.status === "0") {
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:IntData.error || IntData.errors || IntData.message || errors.NotFound404}));
                  }
            }

            if(IntError){
                  dispatch(setSpinner({status:""}));
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:IntError.error || IntError.data.error || IntError.data.message || errors.NotFound404}));
            }
      
      },[IntData, IntIsSuccess, IntError, IntIsLoading, dispatch])

      useEffect(()=>{
            
            if(WebIsSuccess) {
                  if(WebData.status === false) {

                        if(WebData.attempt === 4) {
                              dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:messages.FailureApplePay}));
                              clearTopStateHandler();
                              dispatch(setSpinner({status:""}));
                        }else {
                              const timer = WebData.attempt === 2 ? 4000 : 5000;
                              setTimeout(()=>{
                                    walletWebhookConfirmation({ paymentIntent : WebData.paymentIntent, attempt : WebData.attempt, sessionid: IntData.session_id })
                              },timer)
                        }

                  } 
                  else if(WebData.status === true) {
                        dispatch(setSpinner({status:""}));
                        dispatch(setWalletParams({
                              isTopUpOpen : true,
                              step : 'step3',
                              paymentMethod : "",
                              topUpAmount : 0
                        }))
                        dispatch(setWalletBalance({ct_wallet_balance:WebData.wallet_balance}))
                  }
            }

            if(WebError){
                  clearTopStateHandler();
                  dispatch(setSpinner({status:""}));
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:WebError.error || WebError.data.error || WebError.data.message || errors.NotFound404}));
            }
      
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[WebData, WebIsSuccess, WebError, WebIsLoading, dispatch])

      useEffect(() => {

            if (stripe &&  wallet.topUpAmount > 0 && domain !== Config.DOMAIN_CONSTANT_AJTIX) {

                  const amountRequest = wallet.topUpAmount*100
                  const pr = stripe.paymentRequest({
                        country: 'GB',
                        currency: 'gbp',
                        total: {
                              label: 'Wallet Credit top-up',
                              amount: parseInt(amountRequest),
                        },
                        disableWallets: ['link'],
                        requestPayerName: true,
                        requestPayerEmail: true,
                  });
                  
                  // Check the availability of the Payment Request API.
                  pr.canMakePayment().then(result => {
                        if (result) {
                              if(domain !== Config.DOMAIN_CONSTANT_AJTIX){
                                    setPaymentRequest(pr);
                              }
                              // setCallIntent(true);
                        }
                  })

                  pr.on('cancel', () =>{
                        dispatch(setSpinner({status:""}));
                  });

                  pr.on('loaderror', function(event) {
                        dispatch(setSpinner({status:""}));
                  });   
            }
            
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [stripe, wallet.topUpAmount]);

      const onPaymentSubmit = () => {
            dispatch(setSpinner({status:"request"}));
            paymentRequest.on("paymentmethod", handlePaymentRequest);
            
      };
          
      const handlePaymentRequest = async (event) => {
            const  clientSecret  = IntData.intent;
            // const  session_id  = IntData.session_id;
            if (!clientSecret) {         
                  event.complete("fail");
                  dispatch(setSpinner({status:""}));
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:'Invalid Client Secret'}));
            }        
            
            const { error, paymentIntent } = await stripe.confirmCardPayment(
                  clientSecret,
                  {
                        payment_method: event.paymentMethod.id,
                  },
                  {
                        handleActions: false,
                  }
            );
        
            if (error) {
                  event.complete("fail");
                  dispatch(setSpinner({status:""}));
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:'Invalid Client Secret'}));
            }
            
            if(paymentIntent.status === "requires_action") {
                  stripe.confirmCardPayment(clientSecret);
            }
           
            //    Show a success message to your customer
            //                   There's a risk of the customer closing the window before callback
            //                   execution. Set up a webhook or plugin to listen for the
            //                   payment_intent.succeeded event that handles any business critical
            //                   post-payment actions.

            setTimeout(()=>{
                  walletWebhookConfirmation({ paymentIntent : paymentIntent, attempt : 1, sessionid: IntData.session_id})
            },2000);

            event.complete("success");
      };

      const clearTopStateHandler = ()=> {
            dispatch(setWalletParams({
                  isTopUpOpen : true,
                  step : 'step1',
                  paymentMethod : "",
                  topUpAmount : 0
            }))
            setTopUp("")
            setEnterAmount(false)
            // setCallIntent(false)
      }

      const options = {
            paymentRequest,
            style: {
              paymentRequestButton: {
                type: 'default',
                // One of 'default', 'book', 'buy', or 'donate'
                // Defaults to 'default'
          
                theme: 'light',
                border: '10px solid red',
                // One of 'dark', 'light', or 'light-outline'
                // Defaults to 'dark'
          
                height: '53px',
                // Defaults to '40px'. The width is always '100%'.
              },
            }
      }

      const paymentTypeHandler = (type)=> {

            if(type === 'card' && enterAmount){
                  dispatch(setWalletParams({
                        isTopUpOpen : true,
                        step : 'step2',
                        paymentMethod : type,
                        topUpAmount : wallet.topUpAmount
                  }))
            }
      }

      const changeTopUphandler = (event)=>{
            if(enterAmount) {
                  setEnterAmount(false);
            }
            let val = validate(event.target.value) ? validate(event.target.value).input : ""

            if (val.charAt(0) === '.') {
                  val = val.replace('.', '0.');
            }
            
            // Remove all digits except for two digits after the decimal point
            if(val.includes(".")){
                  let index = val.indexOf('.');
                  if (index !== -1) {
                        val = val.substring(0, index + 3); // str is now "2.22"
                  }
            }

            // val = parseFloat(val).toFixed(2).replace(/\.?0+$/, ""); // num is now "2.20"

            setTopUp(val);
      }

      const amountHandler = ()=>{

            if(topUp >= 1 && topUp < 1000) {

                  setEnterAmount(true);
                  dispatch(setWalletParams({
                        isTopUpOpen : true,
                        step : wallet.step,
                        paymentMethod : wallet.paymentMethod,
                        topUpAmount : parseFloat(topUp).toFixed(2)
                  }))
                  // setCallIntent(true)

            } else {
                  
                  let message = errors.WalletTopUp.enterAmount
                  if(topUp >= 1000) {
                        message =  errors.WalletTopUp.excedAmountLimit
                  }
                  setError(prevState=>{
                        return {
                              ...prevState,
                              error:true,
                              errorMessage:message
                        }
                  })
            }
      }

      const focusHandler = ()=> {
            setError(initialError);
      }

      const validate = (s) => {
            var rgx = /^[0-9]*\.?[0-9]*$/;
            return s.match(rgx);
      }

      
      const termsHandler = ()=> {
            if(ct_web_view_session){
                  dispatch(setTermsModal({ termsModal : !wallet.termsModal}))
            } else {
                  // navigate(`${routesName.Terms}?wallet`, { target: '_blank', myData: "wallet" });
                  window.open(`${routesName.Terms}?myData=wallet`, "_blank");
            }
      }

      let siteBaseURl = URL.SITE_BASE_URL
      if(domain === Config.DOMAIN_CONSTANT_AJTIX) {
            siteBaseURl = URL.AJ_SITE_BASE_URL
      } else if(domain === Config.DOMAIN_CONSTANT_TFM) {
            siteBaseURl = URL.TFM_SITE_BASE_URL
      }

      return (
            <div id="walletTopupStep1" className="content content__payment-options content__topup-options border-bottom">
                  <div className="content__body">

                        <h4 className="heading">Enter the amount you’d like to top up:</h4>
                        <div className={["form-group-wrapper d-flex flex-column flex-md-row two-cols-md gap-4",error.error ? "for_flexi_error_design" : ""].join(" ")}>
                              <div className="input-group input-group__amount input-group__as-form-control">
                                    <span className="input-group-text fw-bold">£</span>
                                    <input id="inputTopupAmount" type="text" className="form-control fc__amount fw-bold" 
                                          value={topUp} 
                                          onChange={changeTopUphandler} 
                                          placeholder="0.00"
                                          onFocus={focusHandler}
                                          onBlur={amountHandler}
                                    />
                                    
                              </div>
                              {error.error && <p className="form-label-error flexi_error_mobile">{error.errorMessage}</p>}
                              <button id="btnEnterTopupAmount" type="button" className="btn btn-primary align-self-md-center" 
                              onClick={amountHandler}
                              style={{marginleft:browserDetect ? "20px":""}}>
                                    Enter
                              </button>
                              
                        </div>
                        {error.error && <p className="form-label-error flexi_error_device">{error.errorMessage}</p>}
                        
                        <hr/>
                        <h4 className="heading">Choose payment method</h4>
                        <button id="btnTopupMethodDebitCredit" type="button" className="btn btn__payment-method w-100" disabled={!enterAmount || IntIsLoading} onClick={()=>paymentTypeHandler('card')}>
                              <span className="button__icon button__icon--debitcredit">

                              </span>
                              <span className="button__text" >
                                    <span className="heading">Debit or Credit Card</span>
                              </span>
                              <span className="button__arrow button__arrow--right">

                              </span>
                        </button>
                        {(paymentRequest && enterAmount && !IntIsLoading) && <div className="payment-request-button">
                         <PaymentRequestButtonElement  options={options} onClick={onPaymentSubmit} />
                        </div>}
                        {/* Added terms & Condition text */}
                        <div className='space-for-apple-pay-button'></div>
                        {domain === Config.DOMAIN_CONSTANT_AJTIX ?<span className="bottom-text">
                        1) Account credit can be used against the cost of any show listed via our <a href={`${siteBaseURl}current-listings`} target="_blank" rel='noreferrer'>Shows</a> tab. 2) It cannot be used for shows found on the West End Offers tab of our website which are fulfilled by third-party ticketing partners <a href="https://ajtickets.tixculture.com/" target="_blank" rel='noreferrer'>(tixculture.com)</a>. 3) Any credit added is non-refundable and cannot be exchanged for cash in part or full.&nbsp;<span style={{"color":"red"}}><span to={`${routesName.Terms}?wallet`} onClick={termsHandler} style={{"color":"red","textDecoration":"underline","cursor":"pointer"}} rel='noreferrer' target="__blank">Full Terms &amp; Conditions.</span></span>
                        </span> : 
                        <span className="bottom-text">
                        1) Account credit can be used against the cost of any show listed via our <a href={`${siteBaseURl}current-listings`} target="_blank" rel='noreferrer'>Shows</a> tab. 2) It cannot be used for shows found on the West End Offers tab of our website which are fulfilled by third-party ticketing partners <a href="https://centraltickets.tixculture.com/" target="_blank" rel='noreferrer'>(tixculture.com)</a> or <a href="https://centraltickets.ticketswitch.com/" target="_blank" rel='noreferrer'>(ticketswitch.com)</a>. 3) Any credit added is non-refundable and cannot be exchanged for cash in part or full.&nbsp;<span style={{"color":"red"}}><span to={`${routesName.Terms}?wallet`} onClick={termsHandler} style={{"color":"red","textDecoration":"underline","cursor":"pointer"}} rel='noreferrer' target="__blank">Full Terms &amp; Conditions.</span></span>
                        </span>}
                        <div className="custom-control custom-checkbox custom-checkbox-sm custom-checkbox__check custom-checkbox-hide-checkbox" >
                              <label className="custom-control-label custom-control-label-hide">
                                    By making payment you agree to our Terms & Conditions
                              </label>
                        </div>
                        
                  </div>
                  {/* <!-- /.content__body --> */}
                  <div className="content__body content__body--total border-top">
                        <div className="subtotal-block">
                              <div className="fw-bold">Total (VAT inc.):</div>
                              <div className="total-amount">£{parseFloat(wallet.topUpAmount).toFixed(2)}</div>
                        </div>
                  </div>
                  {/* <!-- /.content__body --> */}
            </div>
      )
}

export default Step1
