import React, { useEffect, useState } from 'react';

// external css
// redux and react hooks
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link, useLocation } from 'react-router-dom';

// child component

// small class
import ticketUptakeVenuesGreen1 from "./../../assets/images/tickets/ticket-uptake-venues-green1.svg"
import ticketUptakeVenuesYellow1Sm from "./../../assets/images/tickets/ticket-uptake-venues-yellow1-sm.svg"

import ticketUptakeVenuesRed from "./../../assets/images/tickets/ticket-uptake-venues-red.svg"
import ticketUptakeVenuesAquamarineSm from "./../../assets/images/tickets/ticket-uptake-venues-aquamarine-sm.svg"

import ticketUptakeVenuesAquamarine from "./../../assets/images/tickets/ticket-uptake-venues-aquamarine.svg"
import ticketUptakeVenuesBlueSm from "./../../assets/images/tickets/ticket-uptake-venues-blue-sm.svg"

import ticketUptakeVenuesYellow2 from "./../../assets/images/tickets/ticket-uptake-venues-yellow2.svg"

// medium class
import ticketUptakeVenuesYellow1 from "./../../assets/images/tickets/ticket-uptake-venues-yellow1.svg"

// large class
import ticketUptakeVenuesBlue from "./../../assets/images/tickets/ticket-uptake-venues-blue.svg"
import ticketUptakeVenuesGreen1Sm from "./../../assets/images/tickets/ticket-uptake-venues-green1-sm.svg"
import ticketUptakeVenuesGreen2 from "./../../assets/images/tickets/ticket-uptake-venues-green2.svg"


import { PageTitle, ItemDummy, NHSCardBodyDummy } from './DummyComponents/UptakeVenue';
import Footer from "../../Layouts/Footer/Footer";

// packages
import { LazyLoadImage } from 'react-lazy-load-image-component'
import OwlCarousel from 'react-owl-carousel';
import { Helmet } from 'react-helmet';
import { Tooltip } from 'react-tooltip'

// Actions & RTK query or mutations
import {  useLazyGetUptakeVenueQuery } from "./../../Services/modules/Uptake";
import { setAlert, updateLocoScroll } from '../../Store/UI';

// Constants
import { errors, messages } from '../../resources/en';
import { UptakePageBannerOptions } from "../../resources/OwlCarouselOptions"
import { URL } from '../../resources/Url';
import { MetaData, MetaDataAj, MetaDataTfm } from '../../resources/MetaData';
import { Config } from '../../Config';

const UptakePageVenues = () => {

      const [displayData,setDisplayData]  =     useState(false)
      const [scroll,setScroll]            =     useState(false)
      const browserDetect                 =     useSelector(state=>state.ui.browserDetect);
      const domain                        =     useSelector(state=>state.user.domain)
      const { id }                        =     useParams();
      const [getUptakeVenue, { data, isSuccess, error, isLoading }] = useLazyGetUptakeVenueQuery();

      const dispatch = useDispatch();
      const location = useLocation()

      useEffect(()=>{
            if(scroll){
                  setScroll(false)
                  dispatch(updateLocoScroll({locoScroll:{change:true,type:'update'}}))
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[scroll])

      useEffect(()=>{
            let is_event = ""
            if(location.pathname.includes("uptake_request_by_venue_all_events")){
                  is_event = "all"
            }
            getUptakeVenue({ venue_id:id, is_event:is_event});

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[getUptakeVenue,id])

      useEffect(()=>{
            if(isSuccess){
                  if(data.status === "1"){
                        setDisplayData(true)
                        setScroll(true)
                  } else if(data.status === "0"){
                        console.log(data)
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.error || data.message || errors.NotFound404}));
                  }
            }

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404}));
            }

      },[data, isSuccess, error, isLoading, dispatch])

      const afterLoadHandler = ()=>{
            // console.log("after Load Image handler..")
            dispatch(updateLocoScroll({locoScroll:{change:true,type:'update'}}))
      }

      let colouredTicketClass = "content__uptake__small"
      if(data) {
            if(data.data){
                  if(data.data.address.length <=13){
                        colouredTicketClass = "content__uptake__small"
                  } else if(data.data.address.length > 13) {
                        colouredTicketClass = "content__uptake__medium"
                  } else if(data.data.address.length > 16) {
                        colouredTicketClass = "content__uptake__large"
                  }
            }
      }

      let Get_Uptake_Feedback_report_URL = URL.Get_Uptake_Feedback_report_URL
      let metaDataSite = MetaData
      if(domain === Config.DOMAIN_CONSTANT_TFM){
            metaDataSite = MetaDataTfm
            Get_Uptake_Feedback_report_URL = URL.Get_Uptake_Feedback_report_URL_TFM
      } else if(domain === Config.DOMAIN_CONSTANT_AJTIX){
            metaDataSite = MetaDataAj
            Get_Uptake_Feedback_report_URL = URL.Get_Uptake_Feedback_report_URL_AJ
      }

      return (
            
            <React.Fragment>
                  {/* <!-- REQUIRED wrapper div for parallax --> */}
                  <div className="scroll-animations position-relative" data-scroll-container>
                        <Helmet>
                              <title>{metaDataSite.UptakeVenue.title}</title>
                              <meta name="description" content={metaDataSite.UptakeVenue.description} />
                              <meta name="keywords" content={metaDataSite.UptakeVenue.keywords} />
                        </Helmet>
                        <main className="main__uptake">

                              <section data-scroll-section className="section__uptake section__uptake-venues">
                                    {/* <!--
                                          NOTE: class "container__block" removes child row paddings and negative margins; and col-* paddings
                                    --> */}
                                    <div className="container-fluid container__block">

                                          <div className="row row__big-heading">
                                                <div className="col-md-12">
                                                      <div className={["content content__uptake content__uptake-venues mx-auto", colouredTicketClass].join(" ")}>
                                                            <div className="content__header">
                                                                  <div className="big-heading text-center">
                                                                        {displayData ? <h1 className="mb-0">{displayData && data.data.address}</h1> :
                                                                        <PageTitle />}
                                                                        {(colouredTicketClass === "content__uptake__small" && domain === "" ) && <>
                                                                        
                                                                        <picture>
                                                                              <source media="(min-width: 992px)" 
                                                                              srcSet={ticketUptakeVenuesGreen1}
                                                                              />
                                                                              <img data-scroll className="ticket ticket-green1" 
                                                                              src={ticketUptakeVenuesYellow1Sm}
                                                                              alt=""/>
                                                                        </picture>
                                                                        <picture>
                                                                              <source media="(min-width: 992px)" 
                                                                              srcSet={ticketUptakeVenuesRed}
                                                                              />
                                                                              <img data-scroll className="ticket ticket-yellow1"
                                                                              src={ticketUptakeVenuesAquamarineSm}
                                                                              alt=""/>
                                                                        </picture>
                                                                        <picture>
                                                                              <source media="(min-width: 992px)" 
                                                                              srcSet={ticketUptakeVenuesAquamarine}
                                                                              />
                                                                              <img data-scroll className="ticket ticket-aquamarine" 
                                                                              src={ticketUptakeVenuesBlueSm}
                                                                              alt=""/>
                                                                        </picture>
                                                                        <picture>
                                                                              <img data-scroll className="ticket ticket-yellow2 d-none d-lg-block" src={ticketUptakeVenuesYellow2}
                                                                              alt=""/>
                                                                        </picture>
                                                                        </>}

                                                                        {(colouredTicketClass === "content__uptake__medium" && domain === "") && <>
                                                                              <picture>
                                                                                    <source media="(min-width: 992px)" srcSet={ticketUptakeVenuesGreen1}/>
                                                                                    <img data-scroll="" className="ticket ticket-green1" src={ticketUptakeVenuesYellow1Sm} alt=""/>
                                                                              </picture>
                                                                              <picture>
                                                                                    <source media="(min-width: 992px)" srcSet={ticketUptakeVenuesYellow1}/>
                                                                                    <img data-scroll="" className="ticket ticket-yellow1" src={ticketUptakeVenuesAquamarineSm} alt=""/>
                                                                              </picture>
                                                                              <picture>
                                                                                    <source media="(min-width: 992px)" srcSet={ticketUptakeVenuesAquamarine}/>
                                                                                    <img data-scroll="" className="ticket ticket-aquamarine" src={ticketUptakeVenuesBlueSm} alt=""/>
                                                                              </picture>
                                                                              <picture>
                                                                                    <img data-scroll="" className="ticket ticket-yellow2 d-none d-lg-block" src={ticketUptakeVenuesYellow2} alt=""/>
                                                                              </picture>
                                                                              <picture>
                                                                                    <img data-scroll="" className="ticket ticket-red d-none d-lg-block" src={ticketUptakeVenuesRed} alt=""/>
                                                                              </picture>
                                                                        </>}
                                                                        {(colouredTicketClass === "content__uptake__large" && domain === ""  )&&<>
                                                                              <picture>
                                                                                    <source media="(min-width: 992px)" srcSet={ticketUptakeVenuesBlue}/>
                                                                                    <img data-scroll="" className="ticket ticket-blue" src={ticketUptakeVenuesGreen1Sm} alt=""/>
                                                                              </picture>
                                                                              <picture>
                                                                                    <source media="(min-width: 992px)" srcSet={ticketUptakeVenuesGreen1}/>
                                                                                    <img data-scroll="" className="ticket ticket-green1" src={ticketUptakeVenuesYellow1Sm} alt=""/>
                                                                              </picture>
                                                                              <picture>
                                                                                    <source media="(min-width: 992px)" srcSet={ticketUptakeVenuesYellow1}/>
                                                                                    <img data-scroll="" className="ticket ticket-yellow1" src={ticketUptakeVenuesAquamarineSm} alt=""/>
                                                                              </picture>
                                                                              <picture>
                                                                                    <source media="(min-width: 992px)" srcSet={ticketUptakeVenuesAquamarine}/>
                                                                                    <img data-scroll="" className="ticket ticket-aquamarine" src={ticketUptakeVenuesBlueSm} alt=""/>
                                                                              </picture>
                                                                              <picture>
                                                                                    <img data-scroll="" className="ticket ticket-yellow2 d-none d-lg-block" src={ticketUptakeVenuesYellow2} alt=""/>
                                                                              </picture>
                                                                              <picture>
                                                                                    <img data-scroll="" className="ticket ticket-green2 d-none d-lg-block" src={ticketUptakeVenuesGreen2} alt=""/>
                                                                              </picture>
                                                                              <picture>
                                                                                    <img data-scroll="" className="ticket ticket-red d-none d-lg-block" src={ticketUptakeVenuesRed} alt=""/>
                                                                              </picture>
                                                                        </>}
                                                                  </div>
                                                            </div>
                                                            {/* <!-- /.content__header --> */}
                                                      </div>
                                                      {/* <!-- /.content.content__uptake --> */}

                                                </div>
                                                {/* <!-- /.col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}

                                          
                                          {displayData ? data.data.booking_information.map((item,index)=>{ 

                                                return (<div className="row" key={item.id}>
                                                      <div className="col-md-12">

                                                            <div className="content content__uptake content__uptake-venues content__uptake-event mx-auto">
                                                                  <div className="content__body">

                                                                        <div className="card card__event border-1 border-radius-10 flex-lg-row mb-0">
                                                                              <div className="card__event-image flex-shrink-0">
                                                                                    <LazyLoadImage className="event-image"srcSet={item.image} src={item.image} afterLoad={afterLoadHandler} />
                                                                              </div>
                                                                              <div className="card-body">
                                                                                    <div className="card-body__row row-event-title-status">
                                                                                          <h5 className="card-title fw-bold">{item.title}</h5>

                                                                                          <div className="status-wrapper d-flex flex-shrink-0 align-items-center">
                                                                                                <span className="heading">Status:</span>
                                                                                                <span className="status">{item.status}</span>
                                                                                          </div>
                                                                                    </div>
                                                                                    {/* <!-- /.card-body__row --> */}

                                                                                    <div className="card-body__row row-uptake-list-progress">
                                                                                          <div className="col col__left">
                                                                                                <ul className="list__uptake-stat list-unstyled">
                                                                                                      <li style={{marginRight:browserDetect ? "30px" : ""}}>
                                                                                                            <div className="stat">
                                                                                                                  <h4 className="stat__number fw-bold">{item.tickets}</h4>
                                                                                                                  <div className="stat__text">Allocated</div>
                                                                                                            </div>
                                                                                                            {/* <!-- /.stat --> */}
                                                                                                      </li>
                                                                                                      <li style={{marginRight: browserDetect ? "30px" :""}}>
                                                                                                            <div className="stat">
                                                                                                                  <h4 className="stat__number fw-bold">{item.purchased}</h4>
                                                                                                                  <div className="stat__text">Booked</div>
                                                                                                            </div>
                                                                                                            {/* <!-- /.stat --> */}
                                                                                                      </li>
                                                                                                      <li>
                                                                                                            <div className="stat">
                                                                                                                  <h4 className="stat__number fw-bold">{item.waiting_list}</h4>
                                                                                                                  <div className="stat__text">Waiting List
                                                                                                                        &nbsp;
                                                                                                                        <span className="icon-tooltip-info" 
                                                                                                                              data-tooltip-id="my-tooltip" 
                                                                                                                              data-tooltip-content="This is the total number of people who have joined the waiting list for all past and current performances of this show."
                                                                                                                        >
                                                                                                                              i
                                                                                                                        </span>
                                                                                                                        <Tooltip id="my-tooltip" style={{ left : 1, fontSize: "15px", width : "250px"}} />
                                                                                                                  </div>
                                                                                                            </div>
                                                                                                            {/* <!-- /.stat --> */}
                                                                                                      </li>
                                                                                                      
                                                                                                </ul>
                                                                                          </div>
                                                                                          {/* <!-- /.col --> */}
                                                                                          <div className="col col__right flex-shrink-0">
                                                                                                <div className="uptake-progress-wrapper">
                                                                                                      <h5 className="heading">Uptake %</h5>
                                                                                                      <div className="progress progress-bar-overlay">
                                                                                                            <div className="progress-value" style={{color :item.percentage > 57 && "white"}}>{item.percentage}%</div>
                                                                                                            <div className={["progress-bar"].join(" ")} role="progressbar" aria-label="Uptake %" style={{"width": item.percentage + "%"}} aria-valuenow={item.percentage} aria-valuemin="0" aria-valuemax="100">
                                                                                                            </div>
                                                                                                      </div>
                                                                                                </div>
                                                                                                {/* <!-- /.uptake-progress-wrapper" --> */}
                                                                                          </div>
                                                                                          {/* <!-- /.col --> */}
                                                                                    </div>
                                                                                    {/* <!-- /.card-body__row --> */}

                                                                                    <div className="card-body__row row-notice-buttons">
                                                                                          <div className="col col__left">
                                                                                                <div className="buttons-wrapper mt-lg-auto">
                                                                                                      <Link className="btn btn-primary" target="_blank" to={`/uptake_request/${item.id}`} rel='noreferrer'>Check Bookings</Link>
                                                                                                </div>
                                                                                          </div>
                                                                                          {/* <!-- /.col --> */}
                                                                                          <div className="col col__right d-lg-flex flex-shrink-0">
                                                                                                <div className="buttons-wrapper mt-lg-auto">
                                                                                                      <a className="btn btn-primary" target="_blank" href={`${Get_Uptake_Feedback_report_URL}${item.id}`} rel='noreferrer'>Member Feedback</a>
                                                                                                </div>
                                                                                          </div>
                                                                                          {/* <!-- /.col --> */}
                                                                                    </div>
                                                                                    {/* <!-- /.card-body__row --> */}

                                                                              </div>
                                                                              {/* <!-- /.card-body --> */}
                                                                        </div>
                                                                        {/* <!-- /.card__event --> */}

                                                                  </div>
                                                                  {/* <!-- .content__body --> */}
                                                            </div>
                                                            {/* <!-- .content --> */}

                                                      </div>
                                                      {/* <!-- .col-md-12 --> */}
                                                      </div>)
                                          }) : <><ItemDummy />
                                          <ItemDummy /></>}

                                          <div className="row">
                                                <div className="col-md-12">
                                                      <div className="content content__uptake mx-auto">
                                                            <div className="content__body" style={{"minHeight" : "30vh"}}>
                                                                  
                                                                  {displayData ? 
                                                                  <>
                                                                  {data.data.booking_information.length ===0 && <div className="card card__event border-radius-10 flex-lg-row mb-0" style={{"minHeight":"35vh","display":"flex","justifyContent":"center","alignItems":"center"}}>
                                                                        <div className="" style={{"text-align": "center","color":"var(--bs-red)"}}>
                                                                              <h2 className='no-uptake-event' style={{"margin":"0px"}}>Currently, there are no live events listed for {data.data.address}</h2>
                                                                        </div>
                                                                  </div>}
                                                                  <OwlCarousel className='card card__uptake card__uptake--thank-you border-0 bg-primary border-radius-10' loop {...UptakePageBannerOptions}>
                                                                        <div className="card-body text-center">
                                                                              <h5 className="card-title fw-bold">Thank you! Together we<br className="d-md-none"/> are helping to support <span className="yellow2">230 NHS Charities</span></h5>
                                                                        </div>
                                                                        {domain !== Config.DOMAIN_CONSTANT_AJTIX &&
                                                                        <div className="card-body text-center ecologi-card bg-mint">
                                                                              <h5 className="card-title fw-bold" style={{"color":"var(--bs-dark)"}}>
                                                                                    {/* and tackling climate change <span style={{"color":"var(--bs-primary)"}}>via Ecologi</span> */}
                                                                                    and investing into carbon avoidance projects <span style={{"color":"var(--bs-primary)"}}>via Ecologi</span>
                                                                              </h5>
                                                                        </div>}
                                                                  </OwlCarousel>
                                                                  </>
                                                                  : <NHSCardBodyDummy />}
                                                                  {/* <!-- /.card__uptake --> */}

                                                            </div>
                                                            {/* <!-- .content__body --> */}
                                                      </div>
                                                      {/* <!-- .content.content__uptake --> */}

                                                </div>
                                                {/* <!-- .col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}

                                    </div>
                                    {/* <!-- /.container-fluid  --> */}
                              </section>
                              {/* <!-- /.section__uptake --> */}

                        </main>

                        <Footer />
                  </div>
                  {/* <!-- /div[data-scroll-container] --> */}

            </React.Fragment>
      )
}

export default UptakePageVenues;
