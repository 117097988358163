import React, { useEffect, useState } from 'react';

// external css
// redux and react hooks
import { useDispatch, useSelector } from 'react-redux';

// child component
// packages
// Actions & RTK query or mutations
import { useDeleteAccountMutation, useLazyCheckDeletionQuery } from '../../../Services/modules/settings';
import { logout } from '../../../Store/User';
import { setAlert } from '../../../Store/UI'; 

// Constants
import { errors, messages } from '../../../resources/en';
import { api } from '../../../Services/Api';

const DeleteAccountContent = () => {
      const [feedback,setFeedback] = useState("");
      const [loading,setLoading] = useState("");
      const showModel = useSelector(state=>state.ui.alert.model)
      const confirm = useSelector(state=>state.ui.alert.type.status);
      const [deleteAccount, { data, isSuccess, isLoading, isFetching, error }]  =    useDeleteAccountMutation();
      const [checkDeletion, { data : cdData, error: cdError }]  =    useLazyCheckDeletionQuery();
      

      const dispatch = useDispatch();

      const confirmationHandler = (event)=>{
            event.preventDefault();
            setLoading(true)
            checkDeletion()
            // dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:'Are you sure you want to delete your account?',type:{value:"confirm",status:false}}))
      }

      useEffect(()=>{
            if(cdData) {
                  if(cdData.status === "1") {
                        if(cdData.booking_count > 0) {
                              dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:`You have ${cdData.booking_count} active bookings.  Because we delete your details when we delete an account, this will mean that your booking will be cancelled without being refunded.  Do you still want to delete your account?`,type:{value:"confirm",status:false}}))

                        } else {
                              dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:'Are you sure you want to delete your account?',type:{value:"confirm",status:false}}))
                        }
                  } 
                  setLoading(false)
            }
            
            if(cdError) {
                  setLoading(false)
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[cdData, cdError, dispatch])

      useEffect(()=>{
            if(isSuccess){
                  if(data.status === "1"){
                        // console.log("logout using del acc")
                        dispatch(logout())
                        dispatch(api.util.resetApiState());
                        dispatch(setAlert({model:true,title:messages.modalSuccessTitle,message: data.message || errors.passwordUpdated}));
                  } else if(data.status === "0"){
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.error || data.message || errors.NotFound404}));
                  }
                  setLoading(false)
            }


            if(error){
                  setLoading(false)
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404}));
            }
            
      },[data, isSuccess, isLoading, isFetching, error, dispatch])

      useEffect(()=>{
            if(confirm){
                  deleteAccount({delete_reason : feedback});
                  // deleteAccount({});
            } 

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[confirm,dispatch, deleteAccount])

      useEffect(()=>{
            if(!showModel) {
                  dispatch(api.util.resetApiState());
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[showModel])

      const onChangeFeedbackHandler = (event)=> {
            setFeedback(event.target.value)
      }


      return (
            <div className="content delete-account">
                  <h4 className="heading">
                        {/* Do you really want to close your account? */}
                        {/* Please help us understand why you want to leave? */}
                        Tell us why you're leaving?  What could we do better?
                  </h4>
                  <div className="form-group">
                        <textarea className="form-control" name="feedback" value={feedback} onChange={onChangeFeedbackHandler} placeholder="Enter your feedback"></textarea>
                  </div>
                  <p>
                        By closing your account you will no longer have access to our listings, or receive emails from us. In line with GDPR regulations, your data will be removed from our marketing database. By law we have to keep details of any bookings you’ve made for 6 years, but those be anonymised.
                  </p>

                  <p>
                        To confirm that you wish to close your account, please click on the ‘Delete my account’ button.
                  </p>
                  <div className="buttons-wrapper">
                        <button type="button" className="btn btn-xl-lg btn-primary" disabled={loading || isLoading}  onClick={confirmationHandler}>Delete my account</button>
                  </div>
            </div>
      )
}

export default DeleteAccountContent
