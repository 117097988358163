import { api } from "../../Api";
import { getCard } from "./getCard";
import { deleteCard } from "./deleteCard";
import { WebInitiateSession } from "./WebInitiateSession";
import { SaveCard } from "./saveCard";
import { paymentFailureResponse } from "./paymentFailureResponse";

export const cardApi = api.injectEndpoints({
            endpoints: build => ({
                  getCard:getCard(build),
                  deleteCard:deleteCard(build),
                  WebInitiateSession:WebInitiateSession(build),
                  saveCard: SaveCard(build),
                  paymentFailureResponse: paymentFailureResponse(build)
      }),
      overrideExisting: true
})

export const {  useLazyGetCardQuery, useDeleteCardMutation, useLazyWebInitiateSessionQuery, useSaveCardMutation, usePaymentFailureResponseMutation  } = cardApi;