/* eslint-disable */
import React, { useState, useEffect } from 'react';

// external css
import 'react-tooltip/dist/react-tooltip.css'

// redux and react hooks
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// child component
import { ImageComponent } from '../../Components';
import { SelectComponent } from '../../Components';

// packages
import { Tooltip } from 'react-tooltip'

// Actions & RTK query or mutations
import { useLazyFetchMemberPreferenceQuery, useUpdateMemberPreferenceMutation } from '../../Services/modules/Preference';
import { loginAfterCompleteRegistration, logout, setNhsRoleCity } from "../../Store/User";
import { updateRegistrationState, setIsRegister, reset } from '../../Store/Register';
import { setAlert, updateLocoScroll } from '../../Store/UI';

// Constants
import { actions, errors, messages } from '../../resources/en';
import { routesName } from '../../resources/RoutesName';

const Step2 = (props) => {

      const [fontError,setFontError] = useState('');
      const [state,setState] = useState(null);
      const [scroll,setScroll] = useState(false);
      const [scrollStart,setScrollStart] = useState(false);

      const [memberPreference, { data, isSuccess, isLoading, isFetching, error }] = useLazyFetchMemberPreferenceQuery();
      const [updatePrefrences, { data : preData, isSuccess : isPreSuccess, isLoading : isPreLoading , isFetching : isPreFetching, error : preError }] =  useUpdateMemberPreferenceMutation();
      const browserDetect     =     useSelector(state=>state.ui.browserDetect);
      const { domain, ct_is_change_password }            =     useSelector(state=>state.user.domain);
      const dispatch          =     useDispatch();
      const navigate          =     useNavigate()
      const { stepsHandler }  =     props;

      useEffect(()=>{
            memberPreference({
                  "categories":true,
                  "regions":true,
                  "notification_preferences":true
            });

      },[memberPreference])

      useEffect(()=>{
            if(scroll) {
                  setScroll(false)
                  dispatch(updateLocoScroll({locoScroll:{change:true,type:'update'}}))
            }

      },[scroll,dispatch])

      useEffect(()=>{
            if(scrollStart) {
                  setScrollStart(false)
                  dispatch(updateLocoScroll({locoScroll:{change:true,type:'start'}}))
            }

      },[scrollStart, dispatch])

      useEffect(() => {
            if(isSuccess){
                  if(data.status === "1"){
                        if(state === null) {
                              setState(data.data);
                        }
                        setScroll(true);
                  } else if(data.status === "0"){
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.error || data.message || errors.NotFound404}));
                  }
            }
            
            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404}));
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [data, isSuccess, isLoading, isFetching, error, dispatch])

      useEffect(() => {
            if(isPreSuccess){
                  if(preData.status === "1") {

                        // if(preData.data?.tfm_details) {
                        //       dispatch(setNhsRoleCity({nhs_city : preData.data?.tfm_details.city || "" ,nhs_role : preData.data?.tfm_details.role || ""}))
                        // }
                        if(preData.data?.logout){
                              dispatch(logout())
                              dispatch(reset())
                              dispatch(setAlert({model:true,title:messages.modalSuccessTitle,message: preData.message || preData.error || errors.NotFound404}));
                              navigate(routesName.Home)
                              return
                        }

                        dispatch(loginAfterCompleteRegistration({auth : true}))
                        dispatch(setIsRegister({isRegister : false}))
                        dispatch(updateRegistrationState({
                              ct_otp_modal_status : "",
                              step : "step3",
                              registration_step : null
                        }))

                        if(ct_is_change_password){
                              navigate(routesName.MyAccount)
                        }
                        // navigate(routesName.CurrentListing)
                        // dispatch(setAlert({model:true,title:messages.modalSuccessTitle,message:preData.error || preData.message || errors.NotFound404}));
                  } else {
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:preData.error || preData.message || errors.NotFound404}));
                  }
            }
            
            if(preError){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:preError.error || preError.data.error || preError.data.message || errors.NotFound404}));
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [preData, isPreSuccess, isPreLoading, isPreFetching, preError,dispatch,stepsHandler])

      const onRegionChangeHandler = (event,index) => {
            event.preventDefault();
            clearErrorHandler();
            const name = event.target.name;
            const value = event.target.value;

            if(name === "regions") {
                  const updateArray = []

                  state.regions.forEach((el,index)=>{
                        if(el.id == value){
                              updateArray.push({...el,selected:true})
                        } else {
                              updateArray.push({...el,selected:false})
                        }
                  })

                  
                  setState(prevState=>{
                        return {
                              ...prevState,
                              regions:updateArray
                        }
                  })

                  setScrollStart(true)
                  setScroll(true)

            } else if(name === "sub_locations") {
                  const updateArray = []
                  let updateRegions = []
                  const selected = event.target.checked ? true : false;

                  state.regions[0].sub_locations.forEach((el,ind)=>{
                        if(index == ind){
                              updateArray.push({...el,selected:selected})
                        } else {
                              updateArray.push({...el})
                        }
                  })

                  updateRegions = [...state.regions]
                  updateRegions[0].sub_locations = updateArray;
                  
                  setState(prevState=>{
                        return {
                              ...prevState,
                              regions:updateRegions
                        }
                  })

            }
      }

      const onCategorieChangeHandler = (event,index)=>{
            event.preventDefault();
            clearErrorHandler();
            const name = event.target.name;
                  
            const updateArray = []
            const selected = event.target.checked ? true : false;
            
            state[name].forEach((el,ind)=>{
                  if(ind == index){
                        updateArray.push({...el,selected:selected})
                  } else {
                        updateArray.push({...el})
                  }
            })
            
            setState(prevState=>{
                  return {
                        ...prevState,
                        [name]:updateArray
                  }
            })


      }
        
      const updateNotificationHandler = (event,index) => {
            event.preventDefault();
            clearErrorHandler();
            const name = event.target.name;
            // const value = event.target.value;

            const updateArray = []
            const selected = event.target.checked ? true :false;
           
            state[name].forEach((el,ind)=>{

                  if(index == 4 && selected ) {

                        if(ind == index){
                              updateArray.push({...el,selected:selected})
                        } else {
                              updateArray.push({...el,selected:false})
                        }
                  } 
                  else {
                        if(ind == index){
                              updateArray.push({...el,selected:selected})
                        } else if(ind == 4) {
                              updateArray.push({...el,selected:false})
                        } else {
                              updateArray.push({...el})
                        }
                  }
            })

            setState(prevState=>{
                  return {
                        ...prevState,
                        [name]:updateArray
                  }
            })

      }

      const updatePrefrencesRequestHandler = (event)=>{
            event.preventDefault();
            setFontError([]);

            const arrayForRegionPref = [];
            const arrayForLondonPref = [];
            const arrayForCatPref = [];
            const arrayForNotPref = [];
            let locationPref = null;
            state.regions.forEach(not=> {
                  if(not.selected){
                        arrayForRegionPref.push(not.id);
                        locationPref = not.key || not.id;
                  }
            });

            if(locationPref == "london_greater_london") {
                  state.regions[0].sub_locations.forEach(lon=> {
                        if(lon.selected){
                              arrayForLondonPref.push(lon.id);
                        }
                  });
            }


            state.categories.forEach(event=> {
                  if(event.selected){
                        arrayForCatPref.push(event.id);
                  }
            });

            state.notification_preferences.forEach(event=> {
                  if(event.selected){
                        arrayForNotPref.push(event.id);
                  }
            });

            const errorStore = [];

            if(arrayForRegionPref.length === 0) {
                  errorStore.push('regions');
            }

            if(arrayForLondonPref.length === 0 && state.regions[0].selected){
                  errorStore.push('sub_locations');
            }

            if(arrayForCatPref.length === 0){
                  errorStore.push('categories');
            }

            if(arrayForNotPref.length === 0) {
                  errorStore.push('notification_preferences');
            }

            if(errorStore.length > 0){
                  setFontError(errorStore)
                  return;
            }

            updatePrefrences(state)
      }

      const clearErrorHandler = ()=>{
            setFontError("");
      }

      let showLondonPrefrences = null;
      if(state!= null) {
            if(state.regions[0].selected) {
                  showLondonPrefrences = true;
            }
      }

      let items = [];
      if(state!==null){
            state.regions.forEach(element => {
                  if(element.selected){
                        items.push(element.id)
                  }
            })
      }

      return (

            <div className="content content__register content__register-part2 mx-auto">
                  <div className="content__header content__header--template border-bottom text-center overflow-hidden">
                        <div className="big-heading">
                              <h1 className="h2 mb-0">
                                    Preferences
                              </h1>
                              {(domain === "" || !domain) && <>
                              <ImageComponent dataScroll className="ticket ticket-orange" type="tickets" src="ticket-register-big-heading1-orange.svg"/>

                              <ImageComponent dataScroll className="ticket ticket-aquamarine" type="tickets" src="ticket-register-big-heading1-aquamarine.svg"/>

                              <ImageComponent dataScroll className="ticket ticket-yellow" type="tickets" src="ticket-register-big-heading1-yellow.svg"/>
                              </>}

                        </div>
                  </div>
                  {/* <!-- .content__header --> */}

                  <div className="content__body p-0 d-flex flex-column flex-lg-row">

                        <div className="col__left border-right-lg d-none d-lg-block">

                              <div className="content">
                                    <div className="content__body">

                                          <div className="wrapper d-flex flex-column flex-md-row flex-lg-column">
                                                <div className="wrapper">
                                                      <h3 className="heading">
                                                            How we’ll use your information
                                                      </h3>
                                                      <p>
                                                            We’ll use the answers you’ve provided to send you show alerts based on the choices you’ve made.  You’ll also be able to manage your email preferences from within your account.
                                                      </p>
                                                </div>
                                                <div className="feature flex-shrink-md-0">
                                                      <ImageComponent className="ticket-1-star" src={props.isAjtix ? "ticket-1star-red-ajtix.svg" :  ( props.isTfm ? "ticket-1star-red-tfm.svg" : "ticket-1star-red.svg")} type="images"/>
                                                </div>
                                          </div>

                                    </div>
                                    {/* <!-- /.content__body --> */}
                              </div>
                              {/* <!-- /.content --> */}

                        </div>
                        {/* <!-- /.col__left --> */}

                        <div className="col__right">

                              <div className="content">
                                    <div className="content__body">
                                          <form>
                                                <div className="row row__form-groups">
                                                      <div className="col-md-12">
                                                            {state !==null &&
                                                            <SelectComponent id="selectArea" label items={items} onChange={onRegionChangeHandler} name="regions" placeholder="Select Area">
                                                                  {state!==null && state.regions.map(item=>{
                                                                        return (
                                                                              <option key={`location${item.id}`} value={item.id}>{item.lable}</option>
                                                                        )
                                                                  })}
                                                            </SelectComponent>}
                                                            {fontError.includes("regions") && <div className="form-group form-group__your-interests mw-100 error-exists">
                                                                  {errors.emailPrefrences.regions}
                                                            </div>}

                                                            {showLondonPrefrences && <React.Fragment>
                                                            <div className="form-group">
                                                                  <label className="heading fw-bold">
                                                                        London Area (select as many as you wish)
                                                                  </label>
                                                                  <ul className="list__tags justify-content-xl-center">
                                                                        {state!==null && state.regions[0].sub_locations.map((item,index)=>{

                                                                        return (<li key={`london${item.id}`} style={{padding:browserDetect? "5px":""}}>
                                                                                    <div className="btn-check-wrapper">
                                                                                          <input className="form-check-input btn-check" type="checkbox" name="sub_locations" id={`sub_locations${item.id}`}  onChange={(event)=>onRegionChangeHandler(event,index)} value={item.id} checked={item.selected}/>
                                                                                          <label className={["btn btn-sm btn-outline-primary hover-primary",item.selected ? "checkedLabel" : "unCheckLabel"].join(" ")} htmlFor={`sub_locations${item.id}`}>
                                                                                                {item.lable}
                                                                                          </label>
                                                                                    </div>
                                                                              </li>)
                                                                        })}
                                                                  </ul>
                                                            </div>
                                                            {fontError.includes("sub_locations") && 
                                                            <div className="form-group form-group__your-interests mw-100 error-exists">
                                                                  {errors.emailPrefrences.sub_locations}
                                                            </div>}
                                                            </React.Fragment>}
                                                            {/* <!-- /.form-group --> */}

                                                            <div className="form-group">
                                                                  <label className="heading fw-bold">
                                                                        Select your interests (select as many as you wish)
                                                                  </label>
                                                                  <ul className="list__tags justify-content-xl-center">
                                                                        {state !== null && state.categories.map((item,index)=>{

                                                                              return (<li key={`event${item.id}${index}`} style={{padding:browserDetect? "5px":""}}>
                                                                                          <div className="btn-check-wrapper">
                                                                                                <input className="form-check-input btn-check" type="checkbox" name="categories" id={`event${item.id}`} checked={item.selected} onChange={(event)=>onCategorieChangeHandler(event,index)} value={item.id}/>
                                                                                                <label className={["btn btn-sm btn-outline-primary hover-primary",item.selected ? "checkedLabel" : "unCheckLabel"].join(" ")} htmlFor={`event${item.id}`}>
                                                                                                      {item.name}
                                                                                                </label>
                                                                                          </div>
                                                                                    </li>)
                                                                              })
                                                                        }
                                                                  </ul>
                                                                  
                                                            </div>
                                                            {/* <!-- /.form-group --> */}
                                                            {fontError.includes("categories") && <div className="form-group form-group__your-interests mw-100 error-exists">
                                                                  {errors.emailPrefrences.categories}
                                                            </div>}

                                                            
                                                      </div>
                                                </div>
                                                {/* <!-- /.row --> */}

                                                <div className="row row__checkboxes">
                                                      <div className="col-md-12">
                                                            <label className="heading fw-bold">
                                                                  Select Your Email Preferences (select as many as you wish)
                                                            </label>

                                                            {state !== null && state.notification_preferences.map((item,index)=>{

                                                                  return      (<><div key={`not${item.id}`} className="form-group form-group__custom-controls" style={{display : "flex"}}>
                                                                                    <div className="custom-control custom-checkbox custom-checkbox__radio">
                                                                                          <input type="checkbox" className={["custom-control-input",item.selected ? "custom-control-input-checked" : ""].join(" ")} id={`not${item.id}`} name="notification_preferences" onChange={(event)=>updateNotificationHandler(event,index)} checked={item.selected}/>
                                                                                          <label className="custom-control-label" htmlFor={`not${item.id}`}>
                                                                                                {item.lable}&nbsp;
                                                                                          </label>
                                                                                    </div>
                                                                                    <label className="custom-control-label tooltip-label" style={{marginLeft :"10px"}}>
                                                                                          <span className="icon-tooltip-info" data-tooltip-id="my-tooltip" data-tooltip-content={item.lable_description}>i</span>
                                                                                    </label>
                                                                              </div>
                                                                              
                                                                              </>)
                                                            })}
                                                            <Tooltip id="my-tooltip" />
                                                            {fontError.includes("notification_preferences") && <div className="form-group form-group__your-interests mw-100 error-exists">
                                                                  {errors.emailPrefrences.notification_preferences}
                                                            </div>}
                                                      </div>
                                                </div>
                                                {/* <!-- /.row --> */}

                                                <div className="row row__cta">
                                                      <div className="col-md-12">
                                                            <div className="form-group form-group__cta">
                                                                  <button type="button" className="btn btn-xl-lg btn-primary" onClick={updatePrefrencesRequestHandler}>
                                                                        {actions.completeRegistration}
                                                                  </button>
                                                            </div>
                                                            {/* <!-- /.form-group --> */}
                                                      </div>
                                                </div>
                                                {/* <!-- /.row --> */}
                                          </form>
                                    </div>
                                    {/* <!-- /.content__body --> */}
                              </div>
                              {/* <!-- /.content --> */}

                        </div>
                        {/* <!-- /.col__right --> */}

                  </div>
                  {/* <!-- /.content__body --> */}

            </div>

      )
}

export default Step2;