export const URL = {
      SITE_BASE_URL :"https://centraltickets.co.uk/",
      AJ_SITE_BASE_URL :"https://ajtix.co.uk/",
      TFM_SITE_BASE_URL :"https://ticketsformedics.co.uk/",
      APPLE_STORE: 'https://apps.apple.com/in/app/central-tickets-member-app/id1599267466',
      GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=centraltickets.co.uk',
      Image_Base_Url : 'https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets%2F',
      Download_Booking_Confirmation_URL : 'https://admin.centraltickets.co.uk/download_booking_confirmation/',
      Download_Booking_TTG_Confirmation_URL : 'https://admin.centraltickets.co.uk/encore-booking-confirmation-download?bookingRef=',
      Get_Uptake_Feedback_report_URL : 'https://centraltickets.co.uk/get_feedback_report/', // Change while live
      Get_Uptake_Feedback_report_URL_AJ : 'https://ajtix.co.uk/get_feedback_report/', // Change while live
      Get_Uptake_Feedback_report_URL_TFM : 'https://ticketsformedics.co.uk/get_feedback_report/', // Change while live
      Uptake_Export_Button_URL : 'https://centraltickets.co.uk/provisional_guest_list/', // Change while live
      Uptake_Export_Button_URL_AJ : 'https://ajtix.co.uk/provisional_guest_list/', // Change while live
      Uptake_Export_Button_URL_TFM : 'https://ticketsformedics.co.uk/provisional_guest_list/', // Change while live
      Trust_Pilot_URL : 'https://www.trustpilot.com/evaluate/centraltickets.co.uk',
      Trust_Pilot_Centraltickets_Page :"https://www.trustpilot.com/review/centraltickets.co.uk",
      TICKETS_FOR_MEDICS_SITE_URL : "https://ticketsformedics.co.uk/",
      NHS_SITE_URL : "https://nhscharitiestogether.co.uk/"
}
