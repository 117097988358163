import React, { useState, useEffect, useRef } from  'react';

// external css
// redux and react hooks
import { useSelector, useDispatch } from 'react-redux';

// child component
// packages
// Actions & RTK query or mutations
import { useCardIdentifyMutation } from '../../../../Services/modules/BookingModule';
import { useLazyGetCardQuery } from '../../../../Services/modules/Card';
import { useLazyWebInitiateSessionQuery, usePaymentFailureResponseMutation } from '../../../../Services/modules/Card';
import { setBookingStep, setPaymentMethod, setSecureData, setGetBookingDetails, resetForAnimation } from '../../../../Store/BookingModule';
import { setAlert } from '../../../../Store/UI';
// import { setBookingHandler } from '../../../../Store/EventDetail';
// import { setWebViewSession } from '../../../../Store/User';
import { setSpinner , setTimer } from '../../../../Store/UI';
import { setMKey, setSessionBlockId } from '../../../../Store/User';

// Constants
import { dateAndTimeFormatHandler } from "../../../../resources/DateHandler";
import { cardValidationPayment } from '../../../../Services';
import { errors, placeholders, messages } from '../../../../resources/en';
import { NameConstants } from '../../../../resources/NameConstants';
import { api } from '../../../../Services/Api';


const intialState = {
      [NameConstants.CTNameOnCard]:{value:"",error:false,errorMessage:""},
      [NameConstants.CTCardNumber]:{value:"",error:false,errorMessage:""},
      [NameConstants.CTExp]:{value:"",error:false,errorMessage:""},
      expirey_date:{value:"",error:false,errorMessage:""},
      [NameConstants.CTCvc]:{value:"",error:false,errorMessage:""}
}

const PaymentViaCard = (props) => {

      const [state,setState]                    =     useState(intialState);
      const [cvv,setCvv]                        =     useState({value:"",error:false,errorMessage:""});
      const [useSavedCard,setUseSavedCard]      =     useState(false);
      const [saveCardFuture,setSaveCardFuture]  =     useState(false);

      const [disbaleSubmit,setDisbaleSubmit]    =     useState(false);

      const bookingModule                       =     useSelector(state=>state.bookingModule);
      const user                                =     useSelector(state=>state.user);
      const eventDetail                         =     useSelector(state=>state.eventDetail);
      const ref                                 =     useRef();

      const [getCard, { data, isSuccess, error, isLoading }] = useLazyGetCardQuery();
      const [WebInitiateSession, { data : WebData, isSuccess : WebIsSuccess, error : WebError, isLoading : WebIsLoading  }] = useLazyWebInitiateSessionQuery();
      const [paymentFailureResponse] = usePaymentFailureResponseMutation();
      const [cardIdentify, { data : cardIdData, isSuccess : cardIdIsSuccess, isLoading : cardIdIsLoading, isFetching : cardIdIsFetching, error : cardIdError }]  =    useCardIdentifyMutation();

      const dispatch = useDispatch();

      useEffect(()=>{

            if(user.ct_is_top_member ===1) {
                  dispatch(api.util.resetApiState('cardApi'));
                  getCard();
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[getCard, user.ct_is_top_member])

      useEffect(()=>{
            if(isSuccess) {
                  if(data.status === "1"){
                        // console.log(data,'get card')
                  } else if(data.status === "0"){
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.error || data.message || errors.NotFound404}));
                  }
            }

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404}));
            }
      
      },[data, isSuccess, error, isLoading, dispatch])

      useEffect(()=>{
            if(WebIsSuccess) {
                  
                  if(WebData.status === "success"){
                        if(useSavedCard){
                              sagePayRequestReusable(WebData.data.merchantSessionKey);
                        } else {
                              sagePayRequest(WebData.data.merchantSessionKey);
                        }
                        dispatch(setMKey({mKey:WebData.data.merchantSessionKey}))
                  } else {
                        props.resumeTimerHandler()
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:WebData.error || WebData.message || errors.NotFound404}));
                  }
            }
            if(WebError){
                  props.resumeTimerHandler()
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:WebError.error || WebError.data.error || WebError.data.message || errors.NotFound404}));
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[WebData, WebIsSuccess, WebError, WebIsLoading, dispatch])

      useEffect(()=>{

            if(bookingModule.secureData !== null ) {
                  if(bookingModule.secureData.status === "3DAuth"){
                        dispatch(setSecureData({data:null}));
                        ref.current.submit();
                  }
            }

      },[bookingModule.secureData, dispatch])

      // Card Identify Data
      useEffect(()=>{
            if(cardIdIsSuccess){
                  // props.closeTimerHandler();
                  dispatch(setMKey({mKey : ""}));

                  if(cardIdData.status !== "0"){ 
                        dispatch(setTimer({timer:null}));
                        dispatch(setSessionBlockId({ct_session_block_id : ""}));

                  }

                  if(cardIdData.status === "3DAuth"){
                        dispatch(setSecureData({data : cardIdData}))
                  } 
                  else if(cardIdData.status === "1"){
                        // dispatch(resetBooking());
                        dispatch(resetForAnimation());
                        dispatch(setGetBookingDetails({getBookingDetails: cardIdData }))
                        // comment this code because using firebase to refetch wallet balance
                        // dispatch(setGetWalletBalance({getWalletBalance:true}));
                        dispatch(setSpinner({status:""}));

                        if(user.ct_web_view_session && window.ReactNativeWebView){
                              // dispatch(setWebViewSession({ct_web_view_session : ""}))
                              window.ReactNativeWebView.postMessage(JSON.stringify({
                                    "stay": true,
                                    "redirect": false,
                                    "redirectTo": "",
                                    "status": 1,
                                    "message": messages.SuccessTicketBooking
                              }));
                        } 
                        dispatch(setBookingStep({step: NameConstants.bookingModuleSteps.bookingSuccess }))
                  } 
                  else if(cardIdData.status === "0") {
                        dispatch(api.util.resetApiState('cardApi'));
                        getCard()
                        // dispatch(reset());
                        // dispatch(resetForAnimation());
                        // dispatch(setBookingHandler({isBooking : false}));
                        dispatch(setSpinner({status:""}));
                        props.resumeTimerHandler()
                        setDisbaleSubmit(false)
                        if(user.ct_web_view_session && window.ReactNativeWebView){
                              // dispatch(setWebViewSession({ct_web_view_session : ""}))
                              window.ReactNativeWebView.postMessage(JSON.stringify({
                                    "stay": true,
                                    "redirect": false,
                                    "redirectTo": "",
                                    "status": 0,
                                    "message": messages.FailureTicketBooking
                              }));
                        } else {
                              dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:cardIdData.error || cardIdData.message || errors.NotFound404 }))
                        }
                  }
                  
            }

            if(cardIdError){
                  props.resumeTimerHandler()
                  setDisbaleSubmit(false)
                  dispatch(setSpinner({status:""}));
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:cardIdError.error || cardIdError.data.error || cardIdError.data.message || errors.NotFound404 }))
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[cardIdData, cardIdIsSuccess, cardIdIsLoading, cardIdIsFetching, cardIdError, dispatch])

      const backOnPaymentViaCard = ()=> {

            // While click on back button wallet balance got increased to prevent that commented this code
            // let walletBalance = user.ct_wallet_balance;

            // if(bookingModule.payment.paymentMethod === NameConstants.wallet || bookingModule.payment.paymentMethod === NameConstants.walletPlusCard) {

            //       walletBalance = parseFloat(parseFloat(bookingModule.payment.useWalletCredit) + parseFloat(user.ct_wallet_balance ));
            //       dispatch(setWalletBalance({ ct_wallet_balance : walletBalance }));
            // }

            dispatch(api.util.resetApiState('cardApi'));
            dispatch(setPaymentMethod({paymentMethod : "", useWalletCredit : 0, useCardCredit : 0, useApplePayCredit : 0}));
            dispatch(setBookingStep({step : NameConstants.bookingModuleSteps.paymentMethod }));
      }

      const onChangeCheckBox = (event)=> {
            setUseSavedCard(event.target.checked);
      }

      const clearCvvErrorHandler = ()=> {
            setCvv(prevState=>{
                  return {
                        ...prevState,
                        error:false,
                        errorMessage:""
                  }
            })
      }

      const changeCvvHandler = (event)=>{
            const actual_val = event.target.value.replace(/[^0-9]/g, '');;
            
            setCvv(prevState=>{
                  return {
                        ...prevState,
                        value:actual_val
                  }
            })
      }

      const clearErrorHandler = ()=>{
            setState(prevState=>{

                  return {
                        ...prevState,
                        [NameConstants.CTNameOnCard]:{...prevState[NameConstants.CTNameOnCard],error:false,errorMessage:""},
                        [NameConstants.CTCardNumber]:{...prevState[NameConstants.CTCardNumber],error:false,errorMessage:""},
                        [NameConstants.CTExp]:{...prevState[NameConstants.CTExp],error:false,errorMessage:""},
                        expirey_date:{...prevState.expirey_date,error:false,errorMessage:""},
                        [NameConstants.CTCvc]:{...prevState[NameConstants.CTCvc],error:false,errorMessage:""}
                  }
            })
      }

      const changeValueHandler = (event)=>{
            const type = event.target.name;
            let val  = event.target.value;
            
            if(type === NameConstants.CTExp) {
                  var str = val;
                  if(str.length > 5){
                        return;
                  }
                  const actual_val = str.replace(/\//g, '');
                  // eslint-disable-next-line no-useless-escape
                  const display_val = val.replace(/^(\d\d)(\d)$/g, '$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2').replace(/[^\d\/]/g, '')

                  // const checkValidation = cardValidationPayment(val,type); 
                  setState(prevState=>{
                        return {
                              ...prevState,
                              // [type]:{...prevState[type],value:display_val,error:!checkValidation.isValid,message:checkValidation.validatingMessage},
                              [type]:{...prevState[type],value:display_val},
                              expirey_date:{...prevState.expirey_date,value:actual_val}
                        }
                  })

            } else{

                  let actual_val = val;
                  if(type === NameConstants.CTCvc){
                        actual_val = val.replace(/[^0-9]/g, '');;
                  }

                  if(type === NameConstants.CTCardNumber){
                        actual_val = val.replace(/[^0-9]/g, '');;
                  }

                  // const checkValidation = cardValidationPayment(actual_val,type); 
                  setState(prevState=>{
                        return {
                              ...prevState,
                              // [type]:{...prevState[type],value:actual_val,error:!checkValidation.isValid,errorMessage:checkValidation.validatingMessage}
                              [type]:{...prevState[type],value:actual_val}
                        }
                  })
            }
            
      }

      const checkBoxHandler = (event) => {
            setSaveCardFuture(event.target.checked);
      }

      const SendPaymentRequestHandler = (event)=> {

            event.preventDefault();
            setDisbaleSubmit(true)
            if(useSavedCard){

                  const checkValidation   =       cardValidationPayment(cvv.value,NameConstants.CTCvc); 

                  if(checkValidation.isValid) {
                        props.pauseTimerHandler()
                        dispatch(setSpinner({status : "request"}))
                        WebInitiateSession();
                  } else {
                        setDisbaleSubmit(false)
                        setCvv(prevState=>{
                              return {
                                    ...prevState,
                                    error:!checkValidation.isValid,
                                    errorMessage :checkValidation.validatingMessage
                              }
                        })
                  }
            } else {

                  let request             =     false;
                  const checkedObject     =     {};
                  const requestAllow      =     [];
                  
                  for(let i in state){
                        
                        const checkValidation   =       cardValidationPayment(state[i].value,i); 
                        checkedObject[i]        =       {...state[i],error:!checkValidation.isValid,errorMessage:checkValidation.validatingMessage}
                        requestAllow.push(!checkValidation.isValid); 
                  }


                  setState(prevState=>{
                        return {
                              ...checkedObject
                        }
                  });

                  request   =       requestAllow.includes(true);
                  if(!request) {
                        props.pauseTimerHandler()
                        dispatch(setSpinner({status : "request"}))
                        WebInitiateSession();
                  } else {
                        setDisbaleSubmit(false)
                  }
            }
      }

      const sagePayRequest = (merchantSessionKey)=>{
               
            window.sagepayOwnForm({ merchantSessionKey: merchantSessionKey })
            .tokeniseCardDetails({
                  cardDetails: {
                        cardholderName: state[NameConstants.CTNameOnCard].value,
                        cardNumber: state[NameConstants.CTCardNumber].value,
                        expiryDate: state.expirey_date.value,
                        securityCode: state[NameConstants.CTCvc].value
                  },
                  onTokenised: function(result) {
                              if (result.success) {
                                    const card_identifier = result.cardIdentifier;
                                    const preData = prepareDataObject(false,card_identifier,merchantSessionKey,saveCardFuture)
                                    cardIdentify(preData)

                              } else{
                                    const preData = prepareDataObject(false,"",merchantSessionKey,saveCardFuture)
                                    paymentFailureResponse({
                                          Request: preData,
                                          block_id : preData.block_id,
                                          payment_response : JSON.stringify(result)
                                    })
                                    props.resumeTimerHandler()
                                    setDisbaleSubmit(false)
                                    dispatch(setSpinner({status : ""}))
                                    const errorsSag = result.errors;
                                    let ct_card_number = false;
                                    let ct_exp = false;
                                    let ct_cvc = false;
                                    let ct_name_on_card = false;

                                    errorsSag.forEach(error => {
                                          let strng       = error.message;
                                          let incNumber   = strng.includes("number");
                                          let incExp      = strng.includes("date");
                                          let incHold     = strng.includes("cardholder");
                                          let incCvv      = strng.includes("code");
                                          if (incNumber) { ct_card_number = true; }
                                          if (incExp) { ct_exp = true; }
                                          if (incHold) { ct_name_on_card = true; }
                                          if (incCvv) { ct_cvc = true; }
                                    }) 
                                    
                                    if (ct_name_on_card) {
                                          setState(prevState=>{
                                                return {
                                                      ...prevState,
                                                      [NameConstants.CTNameOnCard]:{...prevState[NameConstants.CTNameOnCard],error:true,errorMessage:errors.SavedCard[NameConstants.CTNameOnCard]}
                                                }
                                          })
                                    }

                                    if (ct_card_number){	
                                          setState(prevState=>{
                                                return {
                                                      ...prevState,
                                                      [NameConstants.CTCardNumber]:{...prevState[NameConstants.CTCardNumber],error:true,errorMessage:errors.SavedCard[NameConstants.CTCardNumber]}
                                                }
                                          })							
                                    }

                                    if (ct_exp) {
                                          setState(prevState=>{
                                                return {
                                                      ...prevState,
                                                      [NameConstants.CTExp]:{...prevState[NameConstants.CTExp],error:true,errorMessage:errors.SavedCard[NameConstants.CTExp]}
                                                }
                                          })
                                    }

                                    if(ct_cvc) {
                                          setState(prevState=>{
                                                return {
                                                      ...prevState,
                                                      [NameConstants.CTCvc]:{...prevState[NameConstants.CTCvc],error:true,errorMessage:errors.SavedCard[NameConstants.CTCvc]}
                                                }
                                          })
                                    }

                                    if(!ct_name_on_card && !ct_card_number && !ct_exp && !ct_cvc){
                                          dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:errors.sagePayError}))
                                    }
                              }
                    }
            });
      }

      const sagePayRequestReusable = (merchantSessionKey)=>{

            window.sagepayOwnForm({ merchantSessionKey: merchantSessionKey })
            .activateReusableCardIdentifier({
                  reusableCardIdentifier: data.data.card.identifier,
                  securityCode: cvv.value,
                  onActivated: function(result) {
                        if (result.success) {
                              const preData = prepareDataObject(true,data.data.card.identifier,merchantSessionKey,saveCardFuture)
                              cardIdentify(preData)
                              
                        } else{
                              const preData = prepareDataObject(true,data.data.card.identifier,merchantSessionKey,saveCardFuture)
                              paymentFailureResponse({
                                    Request: preData,
                                    block_id : preData.block_id,
                                    payment_response : JSON.stringify(result)
                              })
                              props.resumeTimerHandler()
                              setDisbaleSubmit(false)
                              const resultErrors = result.errors;

                              let ct_cvc = false;
                              let ct_cvc_errorMessage = ""

                              resultErrors.forEach(error => {
                                    let strng       = error.message;
                                    let incCvv      = strng.includes("code");
                                    if (incCvv) { 
                                          ct_cvc = true; 
                                          ct_cvc_errorMessage = strng
                                    }
                              }) 

                              if(ct_cvc){
                                    setCvv(prevState=>{
                                          return {
                                                ...prevState,
                                                error : true, 
                                                errorMessage : ct_cvc_errorMessage
                                          }
                                    })
                              } else {
                                    dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:errors.sagePayError}));
                              }

                              dispatch(setSpinner({status : ""}))
                        }
                  }
            });
      }

      const prepareDataObject = (reusable, card_identifier, mKey,saveCardFuture) => {
      
            let payment_method = "";

            let ticket_type_master_id = bookingModule.performanceInfo.ticket_type_master_id || ""
            let ticket_type_id = bookingModule.performanceInfo.ticket_type_id

            const date = dateAndTimeFormatHandler(bookingModule.performanceInfo.date_time,bookingModule.performanceInfo.show_time).split(" ");
            payment_method = bookingModule.payment.paymentMethod === "card" ? "full_card_balance" : "card_wallet_balance";

            const data = {
                  event_id: bookingModule.event_id,
                  token: user.ct_access_token,
                  client: user.ct_web_view_session ? "app" : "web",
                  request_client : user.ct_web_view_session ? "app" : "web",
                  coupan_code_id : bookingModule.bookingDetails.promocode.coupan_code_id || "",
                  promocode: bookingModule.bookingDetails.promocode.promocode,
                  performance_id: bookingModule.bookingDetails.showId,
                  discount_amount: bookingModule.bookingDetails.promocode.discount,
                  price: bookingModule.bookingDetails.price,
                  wallet_balance: parseFloat(user.ct_wallet_balance),
                  amount_to_be_paid_by_card: parseFloat(bookingModule.payment.useCardCredit),
                  wallet_balance_used: parseFloat(bookingModule.payment.useWalletCredit),
                  pennies_amount: bookingModule.bookingDetails.charity ? NameConstants.charity : 0,
                  totalAmount:  parseFloat(parseFloat(bookingModule.payment.useWalletCredit) + parseFloat(bookingModule.payment.useCardCredit)),
                  flexi_price: bookingModule.bookingDetails.flexAmount,
                  is_flexi: bookingModule.bookingDetails.isFlexi,
                  tickets: bookingModule.bookingDetails.quantity,
                  group_availablity: bookingModule.group_availablity,
                  ticket_type_master_id: ticket_type_master_id,
                  ticket_type_id: ticket_type_id,
                  block_id: user.ct_session_block_id,
                  event_name: bookingModule.title,
                  address: eventDetail.eventInfo.address,
                  performance_date_time: bookingModule.performanceInfo.show_time,
                  merchantSessionKey: mKey,
                  card_identifier: card_identifier,
                  reusable_payment: reusable,
                  reusable: saveCardFuture,
                  get_current_id: "order_summary_view",
                  get_next_id: "card_details_view",
                  apple_pay_id: false,
                  raw_date: date[0],
                  start_time: date[2],
                  ticket_type_name: "",
                  payment_method: payment_method,
                  timer: "",
                  session_id : user.ct_web_view_session ? user.ct_web_view_session : "",
            }

            return data;
      }

      let hasCard = false;
      if(data) {
            hasCard = data.data.has_card;
      }

      return (
            <>
            {bookingModule.secureData &&
                  <form  ref={ref}  method="POST" style={{"visibility": "hidden","height":"0px"}} action={bookingModule.secureData && bookingModule.secureData.acsUrl}>
                        <input type="hidden" name="creq" value={bookingModule.secureData && bookingModule.secureData.cReq}/>
                        <input type="hidden" name="acsTransID" value={bookingModule.secureData && bookingModule.secureData.acsTransId}/>
                        <input type="hidden" name="threeDSSessionData" value={bookingModule.secureData && bookingModule.secureData.threeDSSessionData}/>
                        <input type="hidden" name="ThreeDSNotificationURL " value={bookingModule.secureData && bookingModule.secureData.ThreeDSNotificationURL} />
                        <button type="submit" style={{"visibility": "hidden"}}></button>
                  </form>
            }
            <div className="bookTicketsStep4PayViaCard__wrapper">
                  <div id="bookTicketsStep4PayViaCard" className="content content__payment-options d-flex">
                        <div className="content__body">
                              {(hasCard && user.ct_is_top_member ===1) && <>
                                    <h4 className="heading">Use your saved card</h4>
                                    <div className='content__body reusecard__wrapper'>
                                          <div className="input-group input-group__as-form-control input-group__as-checkbox">
                                                <span className="input-group-text">* * * *&nbsp;&nbsp;* * * *&nbsp;&nbsp;* * * *&nbsp;&nbsp;{data.data.card.last_digits}</span>
                                                <div className="custom-control custom-checkbox custom-checkbox__check">
                                                      <input type="checkbox" className="custom-control-input" id="bookTicketsUseSavedCard" checked={useSavedCard} onChange={onChangeCheckBox}/>
                                                      <label className="custom-control-label" htmlFor="bookTicketsUseSavedCard"></label>
                                                </div>
                                          </div>
                                          <div className="form-group form-group__cvc input-group__as-CVC">
                                                      <input type="text" className="form-control" 
                                                      id="walletTopupReuseCardCVC"
                                                      name={NameConstants.CTCvc} 
                                                      placeholder={placeholders[NameConstants.CTCvc]} 
                                                      onFocus={clearCvvErrorHandler}
                                                      onChange={changeCvvHandler} 
                                                      value={cvv.value}
                                                />
                                                {cvv.error && <p className="form-label-error">{cvv.errorMessage}</p> }
                                          </div>
                                    <hr/>
                                    </div>
                              </>}
                              <h4 className="heading heading__use-new-card">Use a new card</h4>
                              <div className="form-groups__use-new-card" style={{"opacity":useSavedCard ? "0.5" :""}}>
                                    <div className="form-group">
                                          <input type="text" className="form-control" id="bookTicketsInputCardName"
                                          name={NameConstants.CTNameOnCard} 
                                          placeholder={placeholders[NameConstants.CTNameOnCard]}
                                          onFocus={clearErrorHandler}
                                          onChange={changeValueHandler} 
                                          value={state[NameConstants.CTNameOnCard].value}
                                          disabled={useSavedCard}
                                          />
                                          {state[NameConstants.CTNameOnCard].error && <p className="form-label-error">{state[NameConstants.CTNameOnCard].errorMessage}</p>}
                                    </div>
                                    <div className="form-group">
                                          <input type="text" className="form-control"                         id="bookTicketsInputCardNum" 
                                          name={NameConstants.CTCardNumber}
                                          placeholder={placeholders[NameConstants.CTCardNumber]}
                                          onFocus={clearErrorHandler} 
                                          onChange={changeValueHandler} 
                                          value={state[NameConstants.CTCardNumber].value}
                                          disabled={useSavedCard}
                                          />
                                          {state[NameConstants.CTCardNumber].error && <p className="form-label-error">{state[NameConstants.CTCardNumber].errorMessage}</p>}
                                    </div>
                                    <div className="form-groups__expiry-cvc d-flex">
                                          <div className="form-group form-group__card-expiry">
                                                <input type="text" className="form-control fc__CardExpiry" name={NameConstants.CTExp} 
                                                placeholder={placeholders[NameConstants.CTExp]} 
                                                onFocus={clearErrorHandler} 
                                                onChange={changeValueHandler} 
                                                value={state[NameConstants.CTExp].value}
                                                disabled={useSavedCard}
                                                />
                                                {state[NameConstants.CTExp].error && <p  className="form-label-error">{state[NameConstants.CTExp].errorMessage}</p>}
                                          </div>
                                          <div className="form-group form-group__cvc">
                                                <input type="text" className="form-control fc__CVC" id="bookTicketsInputCardCVC" 
                                                name={NameConstants.CTCvc} 
                                                placeholder={placeholders[NameConstants.CTCvc]} 
                                                onFocus={clearErrorHandler} 
                                                onChange={changeValueHandler} 
                                                value={state[NameConstants.CTCvc].value}
                                                disabled={useSavedCard}
                                                />
                                                {state[NameConstants.CTCvc].error && <p className="form-label-error">{state[NameConstants.CTCvc].errorMessage}</p>}
                                          </div>
                                    </div>
                                    {/* <!-- /.form-groups__expiry-cvc --> */}
                                    {(!hasCard && user.ct_is_top_member ===1) &&
                                    <div className="checkbox-wrapper">
                                          <div className="custom-control custom-control-sm-label custom-checkbox custom-checkbox__check">
                                                <input type="checkbox" className="custom-control-input" id="bookTicketsSaveCard" onChange={checkBoxHandler} checked={saveCardFuture} disabled={useSavedCard}/>
                                                <label className="custom-control-label" htmlFor="bookTicketsSaveCard">Save card for future purchases</label>
                                          </div>
                                    </div>}
                              </div>
                              {/* <!-- /.form-groups__use-new-card --> */}
                              <br className="d-none d-xxl-block"/>
                        </div>
                        {/* <!-- /.content__body --> */}

                  
                  </div>
                  <div className="content__footer__wrapper">
                        <div className="content__body content__body--total border-top">
                              <div className="subtotal-block">
                                    <div className="label">Total (VAT inc.):</div>
                                    <div className="total-amount">£{parseFloat(bookingModule.payment.useCardCredit).toFixed(2)}</div>
                              </div>
                        </div>
                        {/* <!-- /.content__body --> */}
                        <div className="content__footer border-top">
                              <div className="buttons-wrapper gap-0">
                                    <button id="btnBackToStep3FromPayViaCard" type="button" className="btn__back btn btn-outline-primary w-50" onClick={backOnPaymentViaCard}>
                                          Back
                                    </button>
                                    <button id="btnContinueStep5" type="button" className="btn btn-primary w-50" disabled={disbaleSubmit} onClick={SendPaymentRequestHandler}>
                                          Book now
                                    </button>
                              </div>
                        </div>
                        {/* <!-- /.content__footer --> */}
                  </div>
            </div>
            </>
      )
}

export default PaymentViaCard;
