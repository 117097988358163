import React from 'react';

// external css
// redux and react hooks
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

// child component
import { HeadingPictureComponent } from "../../../Components";
import StepComponent from './StepComponent';
import { ImageComponent } from '../../../Components';

// packages
// Actions & RTK query or mutations
// Constants
import { routesName } from '../../../resources/RoutesName';
import { URL } from '../../../resources/Url';
import { Config } from '../../../Config';

const TicketBookingSteps = () => {

      const domain = useSelector(state=>state.user.domain)

      const getDateForEcologiLogo = ()=>{
            const date = Date.now()
            return `ecology_logo_red.png?v=${date}`
      }

      let ecologiLogoName = "ecology_logo_red.png?v=1";
      ecologiLogoName = getDateForEcologiLogo()

      return (
          
            <section data-scroll-section className="section__ticket-booking-steps">
                  <div className="container-fluid">
                        <div className="row align-items-xl-stretch">
                              <div className="col-xl-6 col-xxl-5">
                                    <div className="content content__just-few-steps d-flex flex-column h-100">
                                          <div className="content__header">
                                                <div className="big-heading big-heading__ticket-booking-process">
                                                      <h2 className="h1 mb-0">Getting <br className="d-none d-xl-block"/>started</h2>

                                                      <HeadingPictureComponent  dataScroll className="ticket-yellow" srcSet="ticket-home-big-heading3-yellow.svg" src="ticket-home-big-heading3-green-sm.svg"/>

                                                      <HeadingPictureComponent dataScroll className="ticket-blue" srcSet="ticket-home-big-heading3-blue.svg" src="ticket-home-big-heading3-blue-sm.svg"/>

                                                      <HeadingPictureComponent dataScroll className="ticket-aquamarine" srcSet="ticket-home-big-heading3-aquamarine.svg" src="ticket-home-big-heading3-aquamarine-sm.svg"/>
                                                     
                                                </div>
                                          </div>
                                          {/* <!-- .content__header --> */}

                                          <div className="content__body">
                                                <p className="fw-bold">
                                                      It’s simple to register and start booking tickets. You can feel good about booking with {domain === "" ? "Central Tickets" :"Tickets For Medics"} knowing that we’re working in support of <a href='https://nhscharitiestogether.co.uk/' target='_blank' rel='noreferrer' style={{textDecoration :"none"}}><span className="blue">NHS Charities Together</span></a> as a Climate Action Workforce with <a href='https://ecologi.com/centralticketslimited' target='_blank' rel='noreferrer' style={{color : "var(--bs-dark)"}}>Ecologi!</a>
                                                </p>
                                          </div>
                                          {/* <!-- .content__body --> */}
                                          <div className="content__footer mt-xl-auto">
                                                <div className='logos-wrapper d-flex content__footer-logo-wrapper'>
                                                      <a href="https://nhscharitiestogether.co.uk/" rel="noreferrer" target="_blank" style={{"textDecoration":"none"}}>

                                                            <ImageComponent className="image logo-nhs-charities-blue d-block" srcSet="logo-nhs-charities-together-blue-296@2x.png" src="logo-nhs-charities-together-blue-296.png" type="images"/>
                                                            <p className="p-notice mb-0" style={{"color":"var(--bs-body-color)"}}>Registered charity no. 1186569</p>
                                                      </a>
                                                      {/* <a href="https://ecologi.com/centralticketslimited" rel="noreferrer" target="_blank">
                                                            <ImageComponent className="image logo-ecologi d-block" srcSet="logo-ecologi-136-red@2x.png" src="logo-ecologi-136-red.png" type="images"/>
                                                      </a> */}

                                                      <a className="logo__ecologi__custom-text" href="https://ecologi.com/centralticketslimited" target="_blank" rel='noreferrer'>
                                                            {/* <span className="custom-text"><span className="trees-count">4.05k</span> trees</span> */}
                                                            {/* <ImageComponent className="logo__ecologi" srcSet="dynamic_text_ecologi_logo_from_gio@2x.png" src="dynamic_text_ecologi_logo_from_gio.png" type="images"/> */}

                                                            {/* <img className="logo__ecologi"  
                                                                  srcSet={`${URL.Image_Base_Url}ecology_logo/${ecologiLogoName} 2x`} 
                                                                  src={`${URL.Image_Base_Url}ecology_logo/${ecologiLogoName}`} alt="" 
                                                            /> */}

                                                            <img className="logo__ecologi"  
                                                                  srcSet={`https://centralticket.nyc3.cdn.digitaloceanspaces.com/ecology_logo/${ecologiLogoName} 2x`} 
                                                                  src={`https://centralticket.nyc3.cdn.digitaloceanspaces.com/ecology_logo/${ecologiLogoName}`} alt="" 
                                                            />

                                                      </a>
                                                </div>

                                                {/* <img className="image logo-nhs-charities-blue d-block" srcSet={`${logoNhsCharitiesTogetherBlue296a2x} 2x`} src={logoNhsCharitiesTogetherBlue296} alt=""/> */}
{/* 
                                                <div className="logos-wrapper d-flex justify-content-center">
                                                      <ImageComponent className="image logo-nhs-charities" srcSet="logo-nhs-charities-together-blue-174@2x.png" src="logo-nhs-charities-together-blue-174.png" type="images"/>
                                                      <ImageComponent className="image logo-ecologi" srcSet="logo-ecologi-136-red@2x.png" src="logo-ecologi-136-red.png" type="images" />
                                                </div> */}

                                          </div>
                                    </div>
                                    {/* <!-- .content --> */}

                              </div>
                              {/* <!-- .col-xl-6 --> */}

                              <div className="col-xl-6 col-xxl-7">
                                    <div className="content content__ticket-booking-steps">
                                          <div className="content__body">
                                                
                                                <StepComponent iconClassName="icon-c mx-auto" iconSrc="icon-c-black.svg" stepNumber="01" stepTitle="Create an account">
                                                      Create a free account via our {domain === "" ? "app or website" : "website"} to access our list of seat-filling shows and offers for the West End.
                                                </StepComponent>
                                                {/* <!-- .feature --> */}

                                                <StepComponent iconClassName="icon-search mx-auto" iconSrc="icon-search-black.svg" stepNumber="02" stepTitle="Search listings">
                                                      Check out what’s available to book.  New shows are added every day so be sure to check regularly to find something you’d like to see.
                                                </StepComponent>
                                                {/* <!-- .feature --> */}


                                                <StepComponent iconClassName="icon-ticket mx-auto" iconSrc="icon-ticket-black.svg" stepNumber="03" stepTitle="Book tickets">
                                                      Tickets are offered on a best available basis and you can book up to six tickets to take along friends and family (you’ll be sat together).
                                                </StepComponent>
                                                {/* <!-- .feature --> */}


                                                <StepComponent iconClassName="icon-location-pin mx-auto" iconSrc="icon-location-pin2-black.svg" stepNumber="04" stepTitle="Turn up & be discreet">
                                                      Arrive at the venue early and make sure to follow the instructions we provide.  They can differ from show to show and we’ll ask you to read those ahead of booking.
                                                </StepComponent>
                                                {/* <!-- .feature --> */}

                                                <div data-scroll data-scroll-repeat className="buttons-wrapper">
                                                      <Link to={routesName.MembershipGuidlines} className="has-icon fw-bold text-decoration-none">Read our membership guidelines<span className="icon-wrapper">
                                                            <ImageComponent className="icon-arrow-left-red" srcSet="" src={domain === Config.DOMAIN_CONSTANT_TFM ? "icon-arrow-right-35x24-red-tfm.svg"  : "icon-arrow-right-35x24-red.svg"} type="images"/>

                                                            {/* <img className="icon-arrow-left-red" src={iconArrowRight35x24Red} alt=""/> */}
                                                            
                                                            </span>
                                                      </Link>
                                                </div>
                                          </div>
                                          {/* <!-- .content__body --> */}
                                    </div>
                                    {/* <!-- .content --> */}
                              </div>
                              {/* <!-- .col-xl-6 --> */}
                        </div>
                        {/* <!-- /.row --> */}
                  </div>
                  {/* <!-- /.container-fluid  --> */}
            </section>
      )
}

export default TicketBookingSteps;
