import React, { useEffect, useState, useRef } from 'react';

// external css
// redux and react hooks
import { useDispatch, useSelector } from 'react-redux';

// child component
// packages
// Actions & RTK query or mutations
import { useLazyGetCardQuery, useDeleteCardMutation, useLazyWebInitiateSessionQuery, useSaveCardMutation, usePaymentFailureResponseMutation } from '../../../Services/modules/Card';
import { setAlert, setDeteleCardModal } from '../../../Store/UI';
import { setMKey, 
      // setWebViewSession 
} from '../../../Store/User';
import { setSecureData } from '../../../Store/BookingModule';

// Constants
import { errors, placeholders, actions,messages } from '../../../resources/en';
import { NameConstants } from '../../../resources/NameConstants';
import { cardValidationPayment } from "./../../../Services";

const intialState = {
      [NameConstants.CTNameOnCard]:{value:"",error:false,errorMessage:""},
      [NameConstants.CTCardNumber]:{value:"",error:false,errorMessage:""},
      [NameConstants.CTExp]:{value:"",error:false,errorMessage:""},
      expirey_date:{value:"",error:false,errorMessage:""},
      [NameConstants.CTCvc]:{value:"",error:false,errorMessage:""}
}

const SavedCardForm = (props) => {

      const [state,setState] = useState(intialState);
      const secureData = useSelector(state=>state.bookingModule.secureData)
      const user = useSelector(state=>state.user)

      const [loading,setLoading]    =     useState(false);
      const ref                     =     useRef();

      const [getCard, { data, isSuccess, error, isLoading }] = useLazyGetCardQuery();
      const [deleteCard, { data : delData, isSuccess : delIsSuccess, error : delError, isLoading : delIsLoading }] = useDeleteCardMutation();
      const [WebInitiateSession, { data : WebData, isSuccess : WebIsSuccess, error : WebError, isLoading : WebIsLoading  }] = useLazyWebInitiateSessionQuery();
      const [SaveCard, { data : CardData, isSuccess : CardIsSuccess, error : CardError, isLoading : CardIsLoading }] = useSaveCardMutation();
      const [paymentFailureResponse] = usePaymentFailureResponseMutation();
      

      const dispatch = useDispatch();

      useEffect(()=>{
            getCard();
      },[getCard])

      useEffect(()=>{

            if(secureData !== null ) {
                  if(secureData.status === "3DAuth"){
                        dispatch(setSecureData({data:null}));
                        ref.current.submit();
                  }
            }

      },[secureData, dispatch])

      useEffect(()=>{
            if(isSuccess) {
                  if(data.status === "1"){

                  } else if(data.status === "0"){
                        // dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.error || data.message || errors.NotFound404}));
                        // alert("get Card status 0")
                  }
            }

            if(error){
                  // alert("get Card error")
                  // dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404}));
            }
      
      },[data, isSuccess, error, isLoading, dispatch])
    
      useEffect(()=>{
            if(delIsSuccess) {
                  if(delData.status === "1"){
                        dispatch(setDeteleCardModal({card:true}))
                  } else if(delData.status === "0"){
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:delData.error || delData.message || errors.NotFound404}));
                  }
            }
            
            if(delError){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:delError.error || delError.data.error || delError.data.message || errors.NotFound404}));
            }

      },[delData, delIsSuccess, delError, delIsLoading, dispatch])

      useEffect(()=>{
            if(WebIsSuccess) {
                  if(WebData.status === "success"){
                        sagePayRequest(WebData.data.merchantSessionKey);
                        dispatch(setMKey({mKey:WebData.data.merchantSessionKey}))
                  } else {
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:WebData.error || WebData.message || errors.NotFound404}));
                  }
            }
            
            if(WebError){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:WebError.error || WebError.data.error || WebError.data.message || errors.NotFound404}));
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[WebData, WebIsSuccess, WebError, WebIsLoading, dispatch])

      useEffect(()=>{
            if(CardIsSuccess) {
                  if(CardData.status === "1"){
                        // dispatch(resetBooking());
                        setState(intialState)
                        setLoading(false)
                        if(user.ct_web_view_session && window.ReactNativeWebView){
                              // dispatch(setWebViewSession({ct_web_view_session : ""}))
                              window.ReactNativeWebView.postMessage(JSON.stringify({
                                    "stay": true,
                                    "redirect": false,
                                    "redirectTo": "",
                                    "status": 1,
                                    "message": messages.SuccessCardStore
                              }));
                        } else {
                              dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:CardData.error || CardData.message || errors.NotFound404}));
                        }
                  } 
                  else if(CardData.status === "3DAuth"){
                        dispatch(setSecureData({data : CardData}))
                        dispatch(setMKey({mKey:''}))
                  } else if(CardData.status === "0") {
                        dispatch(setMKey({mKey:''}))
                        setState(intialState)
                        setLoading(false)
                        if(user.ct_web_view_session && window.ReactNativeWebView){
                              // dispatch(setWebViewSession({ct_web_view_session : ""}))
                              window.ReactNativeWebView.postMessage(JSON.stringify({
                                    "stay": true,
                                    "redirect": false,
                                    "redirectTo": "",
                                    "status": 0,
                                    "message": messages.FailureCardStore
                              }));
                        } else {
                              dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:CardData.error || CardData.message || errors.NotFound404}));
                        }
                        
                  }
            }
            
            if(CardError){
                  setLoading(false)
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:CardError.error || CardError.data.error || CardError.data.message || errors.NotFound404}));
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[CardData, CardIsSuccess, CardError, CardIsLoading, dispatch])

      const deleteCardHandler = ()=>{
            deleteCard({id:data.data.card.id})
      }

      const changeValueHandler = (event)=>{
            const type = event.target.name;
            let val  = event.target.value;
            
            if(type === NameConstants.CTExp) {
                  var str = val;
                  if(str.length > 5){
                        return;
                  }
                  const actual_val = str.replace(/\//g, '');
                  // eslint-disable-next-line no-useless-escape
                  const display_val = val.replace(/^(\d\d)(\d)$/g, '$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2').replace(/[^\d\/]/g, '')

                  // const checkValidation = cardValidationPayment(val,type); 
                  setState(prevState=>{
                        return {
                              ...prevState,
                              // [type]:{...prevState[type],value:display_val,error:!checkValidation.isValid,message:checkValidation.validatingMessage},
                              [type]:{...prevState[type],value:display_val},
                              expirey_date:{...prevState.expirey_date,value:actual_val}
                        }
                  })

            } else{

                  let actual_val = val;
                  if(type === NameConstants.CTCvc){
                        actual_val = val.replace(/[^0-9]/g, '');;
                  }

                  if(type === NameConstants.CTCardNumber){
                        actual_val = val.replace(/[^0-9]/g, '');;
                  }

                  // const checkValidation = cardValidationPayment(actual_val,type); 
                  setState(prevState=>{
                        return {
                              ...prevState,
                              // [type]:{...prevState[type],value:actual_val,error:!checkValidation.isValid,errorMessage:checkValidation.validatingMessage}
                              [type]:{...prevState[type],value:actual_val}
                        }
                  })
            }
            
      }

      const clearErrorHandler = ()=>{
            setState(prevState=>{

                  return {
                        ...prevState,
                        [NameConstants.CTNameOnCard]:{...prevState[NameConstants.CTNameOnCard],error:false,errorMessage:""},
                        [NameConstants.CTCardNumber]:{...prevState[NameConstants.CTCardNumber],error:false,errorMessage:""},
                        [NameConstants.CTExp]:{...prevState[NameConstants.CTExp],error:false,errorMessage:""},
                        expirey_date:{...prevState.expirey_date,error:false,errorMessage:""},
                        [NameConstants.CTCvc]:{...prevState[NameConstants.CTCvc],error:false,errorMessage:""}
                  }
            })
      }

      const SendPaymentRequestHandler = ()=> {
                
            let request             =     false;
            const checkedObject     =     {};
            const requestAllow      =     [];
            
            for(let i in state){
                  
                  const checkValidation   =       cardValidationPayment(state[i].value,i); 
                  checkedObject[i]        =       {...state[i],error:!checkValidation.isValid,errorMessage:checkValidation.validatingMessage}
                  requestAllow.push(!checkValidation.isValid); 
            }

            setState(prevState=>{
                  return {
                        ...checkedObject
                  }
            });

            request   =       requestAllow.includes(true);
            if(!request) {
                  setLoading(true)
                  WebInitiateSession();
            }

      }

      const sagePayRequest = (merchantSessionKey)=>{
               
            window.sagepayOwnForm({ merchantSessionKey: merchantSessionKey })
            .tokeniseCardDetails({
                  cardDetails: {
                        cardholderName: state[NameConstants.CTNameOnCard].value,
                        cardNumber: state[NameConstants.CTCardNumber].value,
                        expiryDate: state.expirey_date.value,
                        securityCode: state[NameConstants.CTCvc].value
                  },
                  onTokenised: function(result) {
                              if (result.success) {
                                    const card_identifier = result.cardIdentifier;
                                    SaveCard({
                                          card_identifier:card_identifier,
                                          mKey:merchantSessionKey,
                                          client: user.ct_web_view_session ? "app" : "web",
                                          request_client : user.ct_web_view_session ? "app" : "web",
                                          session_id : user.ct_web_view_session ? user.ct_web_view_session : "",
                                    })

                              } else{

                                    paymentFailureResponse({
                                          type : 'card',
                                          payment_response : JSON.stringify(result)
                                    })
                                    setLoading(false)
                                    const errorsSag = result.errors;
                                    let ct_card_number = false;
                                    let ct_exp = false;
                                    let ct_cvc = false;
                                    let ct_name_on_card = false;

                                    errorsSag.forEach(error => {
                                          let strng       = error.message;
                                          let incNumber   = strng.includes("number");
                                          let incExp      = strng.includes("date");
                                          let incHold     = strng.includes("cardholder");
                                          let incCvv      = strng.includes("code");
                                          if (incNumber) { ct_card_number = true; }
                                          if (incExp) { ct_exp = true; }
                                          if (incHold) { ct_name_on_card = true; }
                                          if (incCvv) { ct_cvc = true; }
                                    }) 

                                    if (ct_name_on_card) {
                                          setState(prevState=>{
                                                return {
                                                      ...prevState,
                                                      [NameConstants.CTNameOnCard]:{...prevState[NameConstants.CTNameOnCard],error:true,errorMessage:errors.SavedCard[NameConstants.CTCardNumber]}
                                                }
                                          })
                                    }

                                    if (ct_card_number){	
                                          setState(prevState=>{
                                                return {
                                                      ...prevState,
                                                      [NameConstants.CTCardNumber]:{...prevState[NameConstants.CTCardNumber],error:true,errorMessage:errors.SavedCard[NameConstants.CTCardNumber]}
                                                }
                                          })							
                                    }

                                    if (ct_exp) {
                                          setState(prevState=>{
                                                return {
                                                      ...prevState,
                                                      [NameConstants.CTExp]:{...prevState[NameConstants.CTExp],error:true,errorMessage:errors.SavedCard[NameConstants.CTExp]}
                                                }
                                          })
                                    }

                                    if(ct_cvc) {
                                          setState(prevState=>{
                                                return {
                                                      ...prevState,
                                                      [NameConstants.CTCvc]:{...prevState[NameConstants.CTCvc],error:true,errorMessage:errors.SavedCard[NameConstants.CTCvc]}
                                                }
                                          })
                                    }

                                    if(!ct_name_on_card && !ct_card_number && !ct_exp && !ct_cvc){
                                          dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:errorsSag[0].message || errors[0].message || errors.sagePayError}))
                                    }
                              }
                  }
            });
      }

      let hasCard = false;
      if(data) {
            hasCard = data.data.has_card;
      }

      return (
            <>
            {secureData &&
                  <form  ref={ref}  method="POST" style={{"visibility": "hidden","height":"0px"}} action={secureData && secureData.acsUrl}>
                          <input type="hidden" name="creq" value={secureData && secureData.cReq}/>
                          <input type="hidden" name="acsTransID" value={secureData && secureData.acsTransId}/>
                          <input type="hidden" name="threeDSSessionData" value={secureData && secureData.threeDSSessionData}/>
                          <input type="hidden" name="ThreeDSNotificationURL " value={secureData && secureData.ThreeDSNotificationURL} />
                          <button type="submit" style={{"visibility": "hidden"}}></button>
                  </form>
            }
            <div className="content saved-card-contetn">
                  
                 
                  {hasCard && <>
                  <div className="row">
                        <div className="col-md-12">
                              <label className="form-label">
                                    Saved Card
                              </label>
                        </div>
                  </div>
                  
                  <div className="row align-items-md-center">
                        <div className="col-md-6 col-lg-4">
                              <div className="form-group">
                                    <input type="text" className="form-control fc__cardOnRecord" value={`**** **** **** ${data.data.card.last_digits}`} readOnly/>
                              </div>
                        </div>
                        <div className="col-md-6 col-lg-5 col__delete-saved-card">
                              <div className="form-group">
                                    <button type="button" className="btn btn-xl-lg btn-primary" onClick={deleteCardHandler}>
                                          {actions.deleteSavedCard}
                                    </button>
                              </div>
                        </div>
                  </div></>}
                  {(!hasCard && user.ct_web_view_session) &&<div className="row">
                        <div className="col-md-12">
                              <label className="form-label" style={{marginBottom :"0px"}}>
                                    No saved cards
                              </label>
                        </div>
                  </div>
                  }

                  {!hasCard && data &&
                  <>
                  <h4 className="heading heading__change-saved-card">Add New Card*</h4>
                  <form>
                        <div className="row">
                              <div className="col-md-6">
                                    <div className="form-group mw-100">
                                          <input type="text" className="form-control" id="myAccntChangeCardInputCardName" name={NameConstants.CTNameOnCard} value={state[NameConstants.CTNameOnCard].value} placeholder={placeholders[NameConstants.CTNameOnCard]} onChange={changeValueHandler} onFocus={clearErrorHandler} disabled={loading}/>
                                          {state[NameConstants.CTNameOnCard].error && <p className='form-label-error'>{state[NameConstants.CTNameOnCard].errorMessage}</p>}
                                    </div>
                              </div>
                              {/* <!-- /.col-md-6 --> */}
                              <div className="col-md-6 ps-xxl-4">
                                    <div className="form-group mw-100">
                                          <input type="text" className="form-control" id="myAccntChangeCardInputCardNum" name={NameConstants.CTCardNumber} value={state[NameConstants.CTCardNumber].value} placeholder={placeholders[NameConstants.CTCardNumber]} onChange={changeValueHandler} onFocus={clearErrorHandler} disabled={loading}/>
                                          {state[NameConstants.CTCardNumber].error && <p className='form-label-error'>{state[NameConstants.CTCardNumber].errorMessage}</p>}
                                    </div>
                              </div>
                              {/* <!-- /.col-md-6 --> */}
                        </div>
                        {/* <!-- /.row --> */}

                        <div className="row">
                              <div className="col-md-12">
                                    <div className="form-groups__expiry-cvc d-flex">
                                          <div className="form-group form-group__card-expiry">
                                                <input type="text" className="form-control fc__CardExpiry" id="myAccntChangeCardInputCardExpiry" name={NameConstants.CTExp} value={state[NameConstants.CTExp].value} placeholder={placeholders[NameConstants.CTExp]} onChange={changeValueHandler} onFocus={clearErrorHandler} disabled={loading}/>
                                                {state[NameConstants.CTExp].error && <p className='form-label-error'>{state[NameConstants.CTExp].errorMessage}</p>}
                                          </div>
                                          <div className="form-group form-group__cvc">
                                                <input type="text" className="form-control fc__CVC" id="myAccntChangeCardInputCardCVC" name={NameConstants.CTCvc} value={state[NameConstants.CTCvc].value} placeholder={placeholders[NameConstants.CTCvc]} onChange={changeValueHandler} onFocus={clearErrorHandler} disabled={loading}/>
                                                {state[NameConstants.CTCvc].error && <p className='form-label-error'>{state[NameConstants.CTCvc].errorMessage}</p>}
                                          </div>
                                    </div>
                                    {/* <!-- /.form-groups__expiry-cvc --> */}
                              </div>
                              {/* <!-- /.col-md-12 --> */}
                        </div>
                        {/* <!-- /.row --> */}
                        <div className="form-group form-group__cta">
                              <button type="button" className="btn btn-xl-lg btn-primary" onClick={SendPaymentRequestHandler} disabled={loading}>{actions.updateCard}</button>
                        </div>
                        <p>*We’ll authorise and refund a charge of £1 to verify the card.</p>
                  </form>
                  </> }
            </div>
            </>

      )
      
}

export default SavedCardForm
