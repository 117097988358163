/* eslint-disable */
import React, { useEffect } from 'react'

// external css
// redux and react hooks
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// child component
import { ImageComponent } from "./../../Components"
import AjtixLogo from "../../assets/images/Ajtix-logo.png"
import AjtixLogoMobile from "../../assets/images/Ajtix-logo-mobile.png"


// packages
// Actions & RTK query or mutations
import { useGetLogoutMutation } from "./../../Services/modules/users";
import { logout } from "./../../Store/User";
import { setAlert } from '../../Store/UI';
import { reset as  resetCurrentBookings } from '../../Store/CurruntBookings';
import { reset as  resetBookingModule } from '../../Store/BookingModule';
import { reset as  resetRegistration } from '../../Store/Register';
import { reset as  resetWallet } from '../../Store/Wallet';

// Constants
import { errors, messages } from '../../resources/en';
import { routesName } from '../../resources/RoutesName';
import { URL } from '../../resources/Url';
import { api } from '../../Services/Api';
import { persistor } from '../../Store';
import { Config } from '../../Config';

const Footer = () => {

      const user = useSelector(state=>state.user);
      const auth = user.auth;

      const dispatch = useDispatch();
      const [getLogout, { data, isSuccess, isLoading, isFetching, error }] = useGetLogoutMutation();

      const location                      =     useLocation();


      useEffect(()=>{
            if(isSuccess){
                  if(data.status === "1"){
                        // console.log("logout using footer logout")
                        dispatch(logout(data.data));
                        dispatch(api.util.resetApiState());

                        dispatch(resetCurrentBookings())
                        dispatch(resetBookingModule())
                        dispatch(resetRegistration())
                        dispatch(resetWallet())

                        persistor.purge(); // Clear the persisted state
                  }
            }
            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.data.error || errors.NotFound404}))
            }

      },[data, isSuccess, isLoading, isFetching, error, dispatch])

      const logoutHandler = (e)=>{
            e.preventDefault();
            getLogout({})
      }

      const getDateForEcologiLogo = ()=>{
            const date = Date.now()
            return `ecology_logo_white.png?v=${date}`
      }

      const getDateForTrustpilotLogo = ()=>{
            const date = Date.now()
            return `Trustpilot_score_banner_red.png?v=${date}`
      }

      let trustpilotLogo = "Trustpilot_score_banner_red.png?v=1";
      trustpilotLogo = getDateForTrustpilotLogo()

      let ecologiLogoName = "ecology_logo_white.png?v=1";
      ecologiLogoName = getDateForEcologiLogo()

      const yearCopyRight = new Date().getFullYear();

      let isTfm = user.domain === Config.DOMAIN_CONSTANT_TFM


      return (
                  <footer data-scroll-section className={["main-footer mt-auto", user.domain === Config.DOMAIN_CONSTANT_AJTIX && "ajtix-main-footer"].join(" ")}>
                        {user.domain !== Config.DOMAIN_CONSTANT_AJTIX ? <>
                        <div className="container-fluid container__footer-menu">
                              <div className="row">
                                    <div className="col-xl-6 col__footer-text">
                                          <div className="col-left__columns d-flex align-items-start justify-content-between justify-content-xl-start">
                                                <div className="col-office-address">
                                                      <h6 className="nav-heading">{isTfm ? "Tickets for Medics" : "Central Tickets"}</h6>
                                                      <p>
                                                            Registered Office
                                                            <br/>71-75 Shelton Street
                                                            <br/>Covent Garden
                                                            <br/>London
                                                            <br/>WC2H 9JQ
                                                      </p>
                                                </div>
                                                {/* <!-- /.col-office-address --> */}

                                                <div className="col-footer-menus d-flex flex-column flex-md-row">
                                                      <div className="col-footer-menu">
                                                            <h6 className="nav-heading">Learn more</h6>
                                                            <ul className="nav flex-column">
                                                                  <li className="nav-item">
                                                                        <Link className="nav-link p-0" to={routesName.Register}>Join</Link>
                                                                  </li>
                                                                  <li className="nav-item">
                                                                        <Link className="nav-link p-0" to={routesName.AboutUs}>
                                                                              About Us
                                                                        </Link>
                                                                  </li>
                                                                  <li className="nav-item">
                                                                        <Link className="nav-link p-0" to={routesName.Faq}>
                                                                              FAQs
                                                                        </Link>
                                                                  </li>
                                                                  <li className="nav-item">
                                                                        <Link className="nav-link p-0" to={routesName.ContactUs}>
                                                                              Contact
                                                                        </Link>
                                                                  </li>

                                                                  <li className="nav-item">
                                                                        {auth ?
                                                                        <Link className="nav-link p-0" to="/" onClick={logoutHandler}>
                                                                              Log Out
                                                                        </Link>
                                                                        :
                                                                        <Link className="nav-link p-0" to={routesName.Login}>
                                                                              Login
                                                                        </Link>}
                                                                  </li>
                                                            </ul>
                                                      </div>
                                                      <div className="col-footer-menu">
                                                            <h6 className="nav-heading">Others</h6>
                                                            <ul className="nav flex-column">
                                                                  <li className="nav-item">
                                                                        <Link className="nav-link p-0" to={routesName.Terms}>Terms & Conditions</Link>
                                                                  </li>
                                                                  <li className="nav-item">
                                                                        <Link className="nav-link p-0" to={routesName.Privacy}>Privacy Policy</Link>
                                                                  </li>
                                                                  
                                                            </ul>
                                                      </div>
                                                </div>
                                                {/* <!-- /.col-footer-menus --> */}
                                          </div>
                                          {/* <!-- /.row --> */}
                                    </div>
                                    {/* <!-- /.col__footer-text --> */}

                                    <div className="col-xl-6 d-xl-flex col__sponsors">
                                          <ul className={["nav ms-xl-auto mt-xl-auto", user.domain !== Config.DOMAIN_CONSTANT_TFM && "justify-content-between", "justify-content-xl-start"].join(" ")}>

                                                {user.domain !== Config.DOMAIN_CONSTANT_TFM && 
                                                <li className="nav-item nav-item__logo--c">
                                                      {user.domain === "" ?
                                                      <Link to="/">
                                                            <ImageComponent className="logo__c" srcSet={isTfm? "logo-c-white-tfm.png" : "logo-c-white@2x.png" }  src={isTfm? "logo-c-white-tfm.png" : "logo-c-white.png" }type="images"/>
                                                      </Link> : null}
                                                </li>}
                                                {/* <li className="nav-item nav-item__logo--fsb">
                                                      <Link to="/">
                                                            <ImageComponent className="logo__fsb" srcSet="logo-fsb-white@2x.png" src="logo-fsb-white.png" type="images"/>
                                                      </Link>
                                                </li> */}
                                                
                                                <li className="nav-item nav-item__logo--NHSCT">
                                                      <a href="https://nhscharitiestogether.co.uk/" target="_blank" rel='noreferrer'>
                                                            <img className="logo__NHSCT"  srcSet={`${URL.Image_Base_Url}logo-nhs-charities-together-white-369.png 2x`} src={`${URL.Image_Base_Url}logo-nhs-charities-together-white-369.png`} alt=""/>
                                                      </a>
                                                </li>
                                                <li className="nav-item nav-item__logo--ecologi">
                                                   <a className="logo__ecologi__custom-text" href="https://ecologi.com/centralticketslimited" target="_blank" rel='noreferrer'>
                                                      <img className="logo__ecologi"  
                                                      srcSet={`https://api.ecologi.com/badges/cpw/5e4951f09acd3700174fd740?white=true&landscape=true 2x`} 
                                                      src={`https://api.ecologi.com/badges/cpw/5e4951f09acd3700174fd740?white=true&landscape=true`} alt="" />
                                                    </a>
                                               </li>

                                               {user.domain !== Config.DOMAIN_CONSTANT_TFM && 
                                               <li className="nav-item nav-item__logo--ecologi">
                                                   <a className="logo__ecologi__custom-text logo-star__custom-text" href="https://www.star.org.uk/" target="_blank" rel='noreferrer'>
                                                      <img className="logo__star-secure"  
                                                      srcSet={`https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/edited%20logo%20red%20border.png 2x`} 
                                                      src={`https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/edited%20logo%20red%20border.png`} alt="" />
                                                    </a>
                                                </li>}

                                               

                                                {/* <li className="nav-item nav-item__logo--cyber-essentials d-none d-lg-flex">
                                                      <a href="#">
                                                            <img className="logo__cyber-essentials" srcset="assets/images/logo-cyber-essentails-white@2x.png 2x" src="assets/images/logo-cyber-essentails-white.png" alt=""/>
                                                      </a>
                                                </li> */}

                                          </ul>
                                    </div>
                                    {/* <!-- /.col__sponsors --> */}

                              </div>
                              {/* <!-- /.row --> */}
                        </div>
                        {/* <!-- /.container__footer-menu --> */}

                        <div data-scroll className="container-fluid container__copyright">
                              <div className="row">
                                    <div className="col col-12 col-xl-6">
                                          <div className="d-flex">
                                                {isTfm ?  
                                                <span>Tickets for Medics is the NHS division of Central Tickets Limited, a Limited Company registered in England (11263393)</span>
                                                : <span>Copyright © {yearCopyRight} – Central Tickets Limited is a Limited Company registered in England (11263393)</span>}
                                          </div>
                                    </div>
                                    {/* <div className="col col-12 col-xl-6 trustpilot-footer">
                                          <div className="d-flex">
                                                <a href={URL.Trust_Pilot_Centraltickets_Page} rel="noreferrer" target="_blank">
                                                      <img className="Trustpilot-reviews" alt="Trustpilot reviews" src={`${URL.Image_Base_Url}Trustpilot/${trustpilotLogo}`}></img>
                                                </a>
                                          </div>
                                    </div> */}
                              </div>
                              {/* <!-- /.row --> */}
                        </div>
                        </> : 
                        <>
                              <div className={["container-fluid container__footer-menu",(location.pathname === routesName.CurrentListing || location.pathname === routesName.MyWallet || location.pathname === routesName.GiftVouchers || location.pathname === routesName.MyBookings || location.pathname.includes("/my-wallet/top-up") || location.pathname.includes("leave-feedback")) ? "border-top-none" : ""].join(" ")}>
                                    <div className="row">
                                          <div className="ajtix-footer-logo">
                                                <a className="ajtix-footer-logo-wrapper" href='/'>
                                                      <img src={AjtixLogoMobile} alt="AJtix"/>
                                                </a>
                                          </div>
                                          <div className="col-xl-12 col__footer-text">

                                                <ul>
                                                      <li className="nav-item">
                                                            <Link className="nav-link p-0" to={routesName.Register}>Register</Link>
                                                      </li>
                                                      <li className="nav-item">
                                                            <Link className="nav-link p-0" to={routesName.Faq}>
                                                                  FAQ's
                                                            </Link>
                                                      </li>
                                                      <li className="nav-item">
                                                            <Link className="nav-link p-0" to={routesName.MembershipGuidlines}>
                                                                  Rules of Membership
                                                            </Link>
                                                      </li>
                                                      <li className="nav-item">
                                                            <Link className="nav-link p-0" to={routesName.ContactUs}>
                                                                  Contact Us
                                                            </Link>
                                                      </li>
                                                      <li className="nav-item">
                                                            <Link className="nav-link p-0" to={routesName.Privacy}>
                                                                  Privacy Policy
                                                            </Link>
                                                      </li>
                                                      <li className="nav-item">
                                                            <Link className="nav-link p-0" to={routesName.Terms}>
                                                                  Terms & Conditions
                                                            </Link>
                                                      </li>


                                                     
                                                </ul>
                                                            
                                          </div>
                                          {/* <!-- /.col__footer-text --> */}
                                    </div>
                              </div>
                              <div data-scroll className="container-fluid container__copyright">
                                    <div className="row">
                                          <div className="col col-12">
                                                <div className="d-flex">
                                                      <span>Copyright @ Allison Jenkins t/a AJ Tickets {yearCopyRight}</span>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </>
                        
                        }
                        {/* <!-- /.container__copyright --> */}
                  </footer>
      )
}

export default Footer;