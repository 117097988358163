/* eslint-disable */
import React, { useState, useEffect } from 'react';

// external css
import ticket1starRed from "../../assets/images/ticket-1star-red.svg";
import ticket1starYellow from "../../assets/images/ticket-1star-red-ajtix.svg";
import ticket1starRedTfm from "../../assets/images/ticket-1star-red-tfm.svg";



// redux and react hooks
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// child component
import { HeadingPictureComponent } from '../../Components';

// packages
import TomSelect from "tom-select";


// Actions & RTK query or mutations
import { useRegistrationWithNhsEmailMutation } from '../../Services/modules/Tfm';
import { setAlert, setOtpEntityId } from '../../Store/UI';
import { setTfmRegister, setIsRegister, updateRegistrationState, setExistingRegisterNumber } from "../../Store/Register"
import { setRegistrationType, setInfoPartialRegistration, setReferral, logout } from '../../Store/User';

// Constants
import { joinValidationRules } from '../../Services/JoinService';
import { actions, placeholders, label, messages, errors } from "../../resources/en";
import { Config } from '../../Config';
import { NameConstants } from '../../resources/NameConstants';

const JoinStep1 = () => {

      const [state,setState]= useState({

            [NameConstants.FirstName]:{value:"",error:false,errorMessage:""},
            [NameConstants.LastName]:{value:"",error:false ,errorMessage:""},
            [NameConstants.PassWord]:{value:"",error:false,errorMessage:""},
            [NameConstants.NHSEmail]:{value:"",error:false,errorMessage:""},
            [NameConstants.NHSEmailConfirm]:{value:"",error:false,errorMessage:""},
            [NameConstants.PhoneNumber]:{value:"",error:false,errorMessage:""},
            [NameConstants.CheckReceiveAlerts]:{value:false,error:false,errorMessage:""},
            [NameConstants.CheckAgreeTOS]:{value:false,error:false,errorMessage:""},

      })

      const user              =     useSelector(state => state.user)
      const register          =     useSelector(state => state.register)

      const dispatch          =     useDispatch()

      const [RegistrationWithNhsEmail, { data : regData, isSuccess, error, isFetching, isLoading }] = useRegistrationWithNhsEmailMutation();

      useEffect(()=>{

            window.scrollTo({top: 0, behavior: 'smooth'});
            const tfmRegister = register.tfmRegister
            if(tfmRegister.state){  

                  setState(prevState=>{
                        return {
                              ...prevState,
                              [NameConstants.FirstName]:{...prevState[NameConstants.FirstName],value:tfmRegister.state[NameConstants.FirstName]},
                              [NameConstants.LastName]:{...prevState[NameConstants.LastName],value:tfmRegister.state[NameConstants.LastName]},
                              [NameConstants.PassWord]:{...prevState[NameConstants.PassWord],value:tfmRegister.state[NameConstants.PassWord]},
                              [NameConstants.NHSEmail]:{...prevState[NameConstants.NHSEmail],value:tfmRegister.state[NameConstants.EmailTfm]},
                              [NameConstants.PhoneNumber]:{...prevState[NameConstants.PhoneNumber],value:tfmRegister.state[NameConstants.PhoneNumber]},
                        }
                  })
            }
            // localStorage.setItem('nhs_ragistration_type',param);

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])


      useEffect(()=>{
      // commenremoved TopSelect for NHS Trust and Nearest City
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])


      // Handler Submit Data for RegistrationWithNhsEmail
      useEffect(()=>{

            if(isSuccess){
                  
                  if(regData.status === "1"){ 
                        localStorage.removeItem('nhs_ajtix');
                        localStorage.removeItem('nhs_ragistration_type');

                        dispatch(setReferral({referral : ""}))
                        dispatch(setOtpEntityId({otp_entity_id  :regData?.data?.otp_entity_id || ""}))
                        dispatch(setRegistrationType({ct_registration_type : ""}))
                        dispatch(setInfoPartialRegistration({
                              auth : false,
                              ct_access_token : regData.data.token,
                              ct_refresh_token : regData.data.refresh_token,
                              ct_register_token : regData.data.token,
                              ct_access_name : regData.data.userInfo.firstName +" "+ regData.data.userInfo.lastName,
                              ct_access_email : regData.data.userInfo.email || "",
                              ct_access_mobilenumber : regData.data.userInfo.mobilenumber || "",
                              ct_is_verified : 'false',
                              ct_firebase_key : regData.data.userInfo.firebase_key,
                              ct_reactive : "0",
                              ct_is_top_member : 0,
                              ct_wallet_balance : 0
                        }))
                        dispatch(setIsRegister({isRegister : true}))
                        dispatch(updateRegistrationState({
                              ct_otp_modal_status : "otpEmail",
                              step : "step2",
                              registration_step : register.registration_step
                        }))

                        // To remember if user change number while verifiying it
                        dispatch(setExistingRegisterNumber({existing_register_number : state[NameConstants.PhoneNumber].value}))

                  }
            } 

           
            if(isSuccess && !isFetching && !isLoading) {

                  if(regData.status === "0"){

                        if(regData.data && regData.data.length > 0){
                              const copy =    {
                                    ...state
                              }
      
                              let message = ""
                              regData.data.errors.forEach(el => {
                                    
                                    if(el.field_name === "fname"){
                                          copy[NameConstants.FirstName] = {...copy[NameConstants.FirstName],error:true,errorMessage:el.message}
                                    } 
                                    else if(el.field_name === "lname"){
                                          copy[NameConstants.LastName] = {...copy[NameConstants.LastName],error:true,errorMessage:el.message}
                                    } 
                                    else if(el.field_name === "email"){
                                          copy[NameConstants.NHSEmail] = {...copy[NameConstants.NHSEmail],error:true,errorMessage:el.message}
                                    } 
                                    else if(el.field_name === "mobilenumber"){
                                          copy[NameConstants.PhoneNumber] = {...copy[NameConstants.PhoneNumber],error:true,errorMessage:el.message}
                                    }
                                    else if(el.field_name === "password"){
                                          copy[NameConstants.PassWord] = {...copy[NameConstants.PassWord],error:true,errorMessage:el.message}
                                    }
                                    else if(el.field_name === "confirm_password"){
                                          copy[NameConstants.ConfirmPassword] = {...copy[NameConstants.ConfirmPassword],error:true,errorMessage:el.message}
                                    }
                                    else {
                                          message = el.message
                                    }
                              })
      
                              setState(prevState=>{
                                    return {
                                          ...prevState,
                                          ...copy
                                    }
                              })
                              if(message !== ""){
                                    dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:message || errors.NotFound404 }))
                              }
                        } else {
                              dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:regData.error || regData.errors || regData.message || errors.NotFound404 }))
                        }

                  } 
            }


            if(error) {
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404 }))
            }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[regData, isSuccess, error, isLoading, isFetching, dispatch])

      const resetState = ()=> {

            setState(prevState=>{

                  return {
                        [NameConstants.FirstName]:{value:"",error:false,errorMessage:""},
                        [NameConstants.LastName]:{value:"",error:false ,errorMessage:""},
                        [NameConstants.PassWord]:{value:"",error:false,errorMessage:""},
                        [NameConstants.NHSEmail]:{value:"",error:false,errorMessage:""},
                        [NameConstants.NHSEmailConfirm]:{value:"",error:false,errorMessage:""},
                        [NameConstants.PhoneNumber]:{value:"",error:false,errorMessage:""},
                        [NameConstants.CheckReceiveAlerts]:{value:false,error:false,errorMessage:""},
                        [NameConstants.CheckAgreeTOS]:{value:false,error:false,errorMessage:""},
                  }
            })
      }

      const changeHandler = (event)=>{
           
            const name = event.target.name;
            let value = event.target.value;
            
            let radioButton = false;

            if(name === NameConstants.FirstName || name === NameConstants.LastName){
                  value = capitalizeFirstLetter(value).trim();
            }

            if(name === NameConstants.CheckReceiveAlerts || name === NameConstants.CheckAgreeTOS || name === NameConstants.CheckAgreeMG){
                  value = event.target.checked;
                  radioButton=true;
            }

            if(name === NameConstants.PhoneNumber){
                  value = event.target.value.replace(/\D/g, '');
            }

            setState(prevState=>{
                  return {
                        ...prevState,
                        [name]:{...prevState[name],value:value}
                  }
            })

            if(radioButton){
                  onfocusHandler();
            }
      }

      const onBlueNameFiledHandler = (event)=> {

            const name = event.target.name;
            let value = event.target.value;
            
            let prefixes = ["Mr", "Mrs", "Miss", "Ms", "Mx", "Dr", "Reverend", "Rev", "Revd Doctor"];
            if(prefixes.includes(value)){
                  value = ""
            }  

            setState(prevState=>{
                  return {
                        ...prevState,
                        [name]:{...prevState[name],value:value}
                  }
            })
      } 

      function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
      }

      const onfocusHandler = ()=>{

            setState(prevState=>{

                  return {
                        ...prevState,
                        [NameConstants.FirstName]:{...prevState[NameConstants.FirstName],error:false},
                        [NameConstants.LastName]:{...prevState[NameConstants.LastName],error:false},
                        [NameConstants.PassWord]:{...prevState[NameConstants.PassWord],error:false},
                        [NameConstants.NHSEmail]:{...prevState[NameConstants.NHSEmail],error:false},
                        [NameConstants.NHSEmailConfirm]:{...prevState[NameConstants.NHSEmailConfirm],error:false},
                        [NameConstants.PhoneNumber]:{...prevState[NameConstants.PhoneNumber],error:false},
                        [NameConstants.CheckReceiveAlerts]:{...prevState[NameConstants.CheckReceiveAlerts],error:false},
                        [NameConstants.CheckAgreeTOS]:{...prevState[NameConstants.CheckAgreeTOS],error:false},
                  }
            })

      }

      const checkForNhsEmailHandler =(event)=>{
            const value = state[NameConstants.NHSEmail].value;
            const errorCheck = joinValidationRules(value,"email");

            if(errorCheck.isValid){
                  if((value.indexOf("@nhs.net") === -1) && (value.indexOf("nhs.uk") === -1) && (value.indexOf("nhs.u") === -1) && (value.indexOf("@nhs.n") === -1) && (value.indexOf("nhs.scot") === -1)){
                        const data = {
                              [NameConstants.FirstName]:state[NameConstants.FirstName].value,
                              [NameConstants.LastName]:state[NameConstants.LastName].value,
                              [NameConstants.PassWord]:state[NameConstants.PassWord].value,
                              [NameConstants.PhoneNumber]:state[NameConstants.PhoneNumber].value,
                              [NameConstants.NHSEmail]:state[NameConstants.NHSEmail].value,
                        }

                        dispatch(setTfmRegister({nhs: false,state : data}));
                        return;
                  }
            }
      }

      const submitJoinDataHandler = (event)=>{

            event.preventDefault();

            const errorStore = [];
            const copy = {

            }
            
            for(let key in state)
            {
                  const errorCheck = joinValidationRules(state[key].value,key,state[NameConstants.NHSEmail].value);
                              
                  if(!errorCheck.isValid)
                  {
                        errorStore.push(key);
                  }       
                  copy[key] = {...state[key],error:!errorCheck.isValid,errorMessage:errorCheck.validationMessage}
                        
            }

            if(errorStore.length > 0)
            {
                  copy["errorExists"] = true;
                  setState(prevState=>{
                        return {
                              ...prevState,
                              ...copy
                        }
                  })
            }
            else
            {

                  dispatch(logout())

                  const ragistration_special = localStorage.getItem('nhs_ragistration_type');
                  const ragistration_type = (ragistration_special !== "undefined" && ragistration_special) ? ragistration_special  : "";
                  const data = {
                        fname:state[NameConstants.FirstName].value,
                        lname:state[NameConstants.LastName].value,
                        password:state[NameConstants.PassWord].value,
                        nhs_email:state[NameConstants.NHSEmail].value,
                        nhs_email_confirm:state[NameConstants.NHSEmailConfirm].value,
                        mobile_number:state[NameConstants.PhoneNumber].value,
                        agree_tos:state[NameConstants.CheckAgreeTOS].value,
                        receive_alerts:state[NameConstants.CheckReceiveAlerts].value,
                        is_aj:localStorage.getItem('nhs_ajtix') || false,
                        ragistration_type  : ragistration_type,
                        code  : user.ct_old_user_verification_code,
                        type :  user.ct_registration_type || "",
                        gift_voucher_id : user.ct_gift_voucher || "",
                        referral_code: user.ct_referral,

                  }

                  // console.log(data)
                  RegistrationWithNhsEmail(data)
            }
      }

      return (

            <div className="content content__register mx-auto">
                  <div className="content__header content__header--template border-bottom text-center overflow-hidden">
                        <div className="big-heading">
                              <h1 className="h2 mb-0">
                                    Register
                              </h1>
                        </div>
                  </div>
                  {/* <!-- .content__header --> */}

                  <div className="content__body p-0 d-flex flex-column flex-lg-row">
                        <div className="col__left border-right-lg d-none d-lg-block">

                              <div className="content">

                                    <div className="content__body">
                                          <div className="wrapper d-flex flex-column flex-md-row flex-lg-column">
                                                <div className="wrapper">
                                                      <h3 className="heading">
                                                            Why become a member?
                                                      </h3>
                                                      <ul className="list list-square-style">
                                                            <li>
                                                                  Save money on show tickets
                                                            </li>
                                                            <li>
                                                                  Seat-filling offers for £4-6.50 per ticket
                                                            </li>
                                                            <li>
                                                                  Discounted offers at £15 or under
                                                            </li>
                                                            <li>
                                                                  Hundreds of shows each week
                                                            </li>
                                                            <li>
                                                                  Discounted West End tickets
                                                            </li>
                                                            <li>
                                                                  Exclusive pre-sale access
                                                            </li>
                                                            <li>
                                                                  Receive hot show alerts
                                                            </li>
                                                            <li>
                                                                  <span className="red fw-bold">It’s free to join!</span>
                                                            </li>
                                                      </ul>
                                                </div>
                                                <div className="feature flex-shrink-md-0">
                                                      <img className="ticket-1-star" src={user.domain === Config.DOMAIN_CONSTANT_AJTIX ? ticket1starYellow :  ( user.domain === Config.DOMAIN_CONSTANT_TFM ? ticket1starRedTfm : ticket1starRed)} alt=""/>
                                                </div>
                                          </div>
                                    </div>
                                    {/* <!-- /.content__body --> */}
                              </div>
                              {/* <!-- /.content --> */}

                        </div>
                        {/* <!-- /.col__left --> */}

                        <div className="col__right">

                              <div className="content">
                                    <div className="content__body">
                                          <form>

                                                <div className="row row__form-fields">

                                                      <div className="col-md-6">
                                                            <div className="form-group">
                                                                  <label htmlFor="registerInputFirstName" className="form-label">
                                                                        {label[NameConstants.FirstName]}
                                                                  </label>
                                                                  <input type="text" className="form-control" id="registerInputFirstName" name={NameConstants.FirstName} placeholder={placeholders[NameConstants.FirstName]} onChange={changeHandler} value={state[NameConstants.FirstName].value} onFocus={onfocusHandler} disabled={false}
                                                                  onBlur={onBlueNameFiledHandler}
                                                                  />
                                                                  {state[NameConstants.FirstName].error &&
                                                                  <div className="form-label-error">
                                                                        {state[NameConstants.FirstName].errorMessage}
                                                                  </div>}
                                                            </div>
                                                            {/* <!-- /.form-group --> */}
                                                      </div>
                                                      <div className="col-md-6">
                                                            <div className="form-group">
                                                                  <label htmlFor="registerInputLastName" className="form-label">
                                                                        {label[NameConstants.LastName]}
                                                                  </label>
                                                                  <input type="text" className="form-control" id="registerInputLastName" placeholder={placeholders.lastName} name={NameConstants.LastName} onChange={changeHandler} value={state[NameConstants.LastName].value} onFocus={onfocusHandler} disabled={false}
                                                                  onBlur={onBlueNameFiledHandler}/>
                                                                  {state.lastName.error && 
                                                                  <div className="form-label-error">
                                                                        {state[NameConstants.LastName].errorMessage}
                                                                  </div>}
                                                            </div>
                                                            {/* <!-- /.form-group --> */}
                                                      </div>

                                                </div>
                                                {/* <!-- /.row --> */}

                                                <div className="row row__form-fields">
                                                      <div className="col-md-12">
                                                            <div className="form-group">
                                                                  <label htmlFor="registerInputPassword" className="form-label">
                                                                        {label[NameConstants.PassWord]}
                                                                  </label>
                                                                  <input type="password" className="form-control" id="registerInputPassword" name={NameConstants.PassWord} placeholder={placeholders.password} onChange={changeHandler} value={state.password.value} onFocus={onfocusHandler} disabled={false}/>
                                                                  {state[NameConstants.PassWord].error &&
                                                                  <div className="form-label-error">
                                                                  {state[NameConstants.PassWord].errorMessage}
                                                                  </div>}
                                                            </div>
                                                            {/* <!-- /.form-group --> */}
                                                      </div>
                                                      
                                                </div>
                                                {/* <!-- /.row --> */}

                                                <div className="row row__form-fields">
                                                      <div className="col-md-12">
                                                            <div className="form-group">
                                                                  <label htmlFor="registerInputEmail" className="form-label">
                                                                        {label[NameConstants.NHSEmail]}
                                                                  </label>
                                                                  <input type="email" className="form-control" id="registerInputEmail" name={NameConstants.NHSEmail} placeholder={placeholders[NameConstants.NHSEmail]} onChange={changeHandler} value={state[NameConstants.NHSEmail].value} onFocus={onfocusHandler} onBlur={checkForNhsEmailHandler} disabled={false}/>
                                                                  {state[NameConstants.NHSEmail].error &&  
                                                                  <div className="form-label-error">
                                                                        {state[NameConstants.NHSEmail].errorMessage}
                                                                  </div>}
                                                            </div>
                                                            {/* <!-- /.form-group --> */}
                                                      </div>
                                                      <div className="col-md-12">
                                                            <div className="form-group">
                                                                  <label htmlFor="registerInputPhoneNumber" className="form-label">
                                                                        {label[NameConstants.NHSEmailConfirm]}
                                                                  </label>
                                                                  <input type="text" className="form-control" id="registerInputEmailConfirm" name={NameConstants.NHSEmailConfirm} placeholder={placeholders[NameConstants.NHSEmailConfirm]} onChange={changeHandler} value={state[NameConstants.NHSEmailConfirm].value} onFocus={onfocusHandler} disabled={false}/>
                                                                  {state[NameConstants.NHSEmailConfirm].error && 
                                                                  <div  className="form-label-error">
                                                                        {state[NameConstants.NHSEmailConfirm].errorMessage}
                                                                  </div>}
                                                            </div>
                                                            {/* <!-- /.form-group --> */}
                                                      </div>
                                                </div>
                                                {/* <!-- /.row --> */}


                                                <div className="row row__form-fields">
                                                      <div className="col-md-12">
                                                            <div className="form-group">
                                                                  <label htmlFor="registerInputPhoneNumber" className="form-label">
                                                                        {label[NameConstants.PhoneNumber]}
                                                                  </label>
                                                                  <input type="text" className="form-control" id="registerInputPhoneNumber" name={NameConstants.PhoneNumber} placeholder={placeholders[NameConstants.PhoneNumber]} onChange={changeHandler} value={state[NameConstants.PhoneNumber].value} onFocus={onfocusHandler} disabled={false}/>
                                                                  {state.phoneNumber.error && 
                                                                  <div  className="form-label-error">
                                                                        {state[NameConstants.PhoneNumber].errorMessage}
                                                                  </div>}
                                                            </div>
                                                            {/* <!-- /.form-group --> */}
                                                      </div>
                                                </div>
                                                {/* <!-- /.row --> */}


                                                <div className="row row__checkboxes">
                                                      <div className="col-md-12">

                                                            <div className="form-group form-group__custom-controls">
                                                                  <div className="custom-control custom-checkbox custom-checkbox__radio">
                                                                        <input type="checkbox" className="custom-control-input" id="checkReceiveAlerts" name={NameConstants.CheckReceiveAlerts} onChange={changeHandler} value={state[NameConstants.CheckReceiveAlerts].value} checked={state[NameConstants.CheckReceiveAlerts].value} onFocus={onfocusHandler} disabled={false}/>
                                                                        <label className="custom-control-label" htmlFor="checkReceiveAlerts">
                                                                              I provide my consent to receive marketing communications from Tickets For Medics. 
                                                                        </label>

                                                                        {state[NameConstants.CheckReceiveAlerts].error && 
                                                                        <div className="form-label-error">
                                                                              {state[NameConstants.CheckReceiveAlerts].errorMessage}
                                                                        </div>}
                                                                  </div>
                                                            </div>
                                                            {/* <!-- /.form-group --> */}

                                                            <div className="form-group form-group__custom-controls">
                                                                  <div className="custom-control custom-checkbox custom-checkbox__radio">
                                                                        <input type="checkbox" className="custom-control-input" id="checkAgreeTerms" name={NameConstants.CheckAgreeTOS} onChange={changeHandler} value={state[NameConstants.CheckAgreeTOS].value} checked={state[NameConstants.CheckAgreeTOS].value} onFocus={onfocusHandler} disabled={false}/>
                                                                        <label className="custom-control-label" htmlFor="checkAgreeTerms">
                                                                              I understand that joining Tickets For Medics is subject to its <Link className="blue text-decoration-none" to="/terms" target="_blank" rel="noopener noreferrer">Terms & Conditions</Link> and <Link className="blue text-decoration-none" to="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy.</Link>
                                                                        </label>
                                                                        {state[NameConstants.CheckAgreeTOS].error &&
                                                                        <div className="form-label-error ">
                                                                              {state[NameConstants.CheckAgreeTOS].errorMessage}
                                                                        </div>}
                                                                  </div>
                                                            </div>
                                                            {/* <!-- /.form-group --> */}

                                                      </div>
                                                </div>
                                                {/* <!-- /.row --> */}

                                                <div className="row row__cta">
                                                      <div className="col-md-12">
                                                            <div className="form-group form-group__cta">
                                                                  <button type="button" className="btn btn-xl-lg btn-primary" onClick={submitJoinDataHandler}/*data-bs-toggle="modal" data-bs-target="#modalVerificationCode"*/
                                                                  disabled={false}>
                                                                        {actions.createAccount}
                                                                  </button>
                                                            </div>
                                                            {/* <!-- /.form-group --> */}
                                                      </div>
                                                </div>
                                                {/* <!-- /.row --> */}
                                          </form>
                                    </div>
                                    {/* <!-- /.content__body --> */}
                              </div>
                              {/* <!-- /.content --> */}

                        </div>
                        {/* <!-- /.col__right --> */}

                  </div>
                  {/* <!-- /.content__body --> */}

            </div>

      )
}

export default JoinStep1
